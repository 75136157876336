import { intensityMetricRecordService } from './../../apiClient/services'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { createObjectsArrayMap } from '@/utils'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useIntensityMetricRecordStore = defineStore(
  'intensityMetricRecord',
  () => {
    const crud = useSocketsCrudFactory(
      intensityMetricRecordService,
      'intensityMetricRecord',
    )

    const intensityMetricRecordsByMetricId = computed(() =>
      createObjectsArrayMap(crud.list.value, 'intensityMetricId'),
    )

    return {
      intensityMetricRecords: crud.list,
      intensityMetricRecordsByMetricId,
      ...crud,
    }
  },
)
