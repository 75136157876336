import axios from 'axios'
import axiosRetry from 'axios-retry'
import rest from '@feathersjs/rest-client'
import { apiOrigin } from '@/config'

axiosRetry(axios, {
  retries: 2,
  retryDelay: axiosRetry.exponentialDelay,
})

export default function restClient(this: any) {
  const axiosInstance = axios.create({
    baseURL: apiOrigin,
    // overwrite headers when using prefetch for API requests
    // headers: {
    //   accept: '*/*',
    // },
  })

  this.configure(rest().axios(axiosInstance))

  axiosInstance.interceptors.response.use(undefined, async (error) => {
    if (
      error.response?.status === 401 &&
      window.location.pathname !== '/login'
    ) {
      return window.location.replace('/logout')
    }

    throw error
  })
}
