<template>
  <Component
    :is="componentType"
    :class="[
      'UIButton',
      `UIButton--theme-${theme}`,
      `UIButton--size-${size}`,
      `UIButton--style-${buttonStyle}`,
      {
        'UIButton--disabled': isDisabled,
        'UIButton--is-full': isFull,
        'UIButton--is-loading': isLoading,
      },
    ]"
    :disabled="isDisabled || isLoading"
  >
    <div v-if="isLoading" class="UIButton__loader">
      <UILoader class="UIButton__loader-icon" />
    </div>
    <div
      :class="[
        'UIButton__content',
        { 'UIButton__content--is-loading': isLoading },
      ]"
    >
      <div v-if="$slots['icon-before']" class="UIButton__icon-before">
        <slot name="icon-before" />
      </div>
      <slot />
      <div v-if="$slots['icon-after']" class="UIButton__icon-after">
        <slot name="icon-after" />
      </div>
    </div>
  </Component>
</template>

<script lang="ts" setup>
import { computed, PropType, useAttrs } from 'vue'
import UILoader from '../loaders/UILoader.vue'

defineProps({
  size: {
    type: String as PropType<'sm' | 'md' | 'lg' | 'xl'>,
    default: 'md',
  },
  theme: {
    type: String as PropType<
      | 'primary'
      | 'secondary'
      | 'success'
      | 'danger'
      | 'warning'
      | 'info'
      | 'light'
      | 'dark'
      | 'option'
    >,
    default: 'primary',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Sets button width to 100%
   */
  isFull: {
    type: Boolean,
    default: false,
  },
  /**
   * Replaces button content with a spinner
   */
  isLoading: {
    type: Boolean,
    default: false,
  },
  /**
   * Sets background to white and font/border colors to the theme
   */
  buttonStyle: {
    type: String as PropType<'filled' | 'outlined' | 'pale'>,
    default: 'filled',
  },
})

const componentType = computed(() => {
  const attrs = useAttrs()
  if (attrs.to) {
    return 'router-link'
  }
  if (attrs.href) {
    return 'a'
  }

  return 'button'
})
</script>

<style lang="scss" scoped>
.UIButton {
  @include trans(0.2s);

  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;
  border-radius: 4px;
  font-weight: 700;
  text-align: center;
  font-family: $font-nunito;
  white-space: nowrap;
  position: relative;
  cursor: pointer;

  background: var(--background);
  color: var(--color);
  border: 1px solid var(--border-color);
  padding: var(--padding);
  font-size: var(--font-size);
  line-height: var(--line-height);

  .UIButton__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .UIButton__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
  }

  .UIButton__content--is-loading {
    opacity: 0;
  }

  .UIButton__icon-before {
    display: flex;
    align-items: center;
  }
  .UIButton__icon-after {
    display: flex;
    align-items: center;
  }

  &.UIButton--is-loading {
    pointer-events: none;
  }

  // Sizes
  &.UIButton--size-sm {
    --padding: 0.25rem 0.75rem;
    --font-size: 0.75rem;
    --line-height: 1.25rem;
  }
  &.UIButton--size-md {
    --padding: 0.4375rem 1.125rem;
    --font-size: 0.8125rem;
    --line-height: 1.25rem;
  }
  &.UIButton--size-lg {
    --padding: 0.6875rem 1.5rem;
    --font-size: 0.9375rem;
    --line-height: 1.25rem;
  }
  &.UIButton--size-xl {
    --padding: 0.625rem 2rem;
    --font-size: 1.125rem;
    --line-height: 2rem;
  }

  // Button themes
  @mixin buttonTheme(
    $main,
    $dark,
    $pale,
    $text,
    $loader: $newcolor-secondary-main
  ) {
    --background: #{$main};
    --border-color: #{$main};
    --color: #{$text};
    --loader-color: #{$loader};

    &:hover {
      --background: #{$dark};
    }

    &.UIButton--style-outlined {
      --background: #{$color-white};
      --color: #{$main};
      --border-color: #{$main};
      --loader-color: #{$newcolor-secondary-main};
    }
    &.UIButton--style-pale {
      --background: #{$pale};
      --color: #{$main};
      --border-color: transparent;
      --loader-color: #{$newcolor-secondary-main};
    }

    &.UIButton--style-outlined,
    &.UIButton--style-pale {
      &:hover {
        --background: #{$main};
        --border-color: #{$main};
        --color: #{$text};
      }
    }
  }
  &.UIButton--theme-primary {
    @include buttonTheme(
      $newcolor-primary-main,
      $newcolor-primary-dark,
      $newcolor-primary-pale,
      $color-white,
      $newcolor-secondary-light
    );
  }

  &.UIButton--theme-secondary {
    @include buttonTheme(
      $newcolor-secondary-main,
      $newcolor-secondary-dark,
      $newcolor-secondary-pale,
      $color-white,
      $newcolor-secondary-light
    );
  }

  &.UIButton--theme-success {
    @include buttonTheme(
      $newcolor-success-main,
      $newcolor-success-dark,
      $newcolor-success-pale,
      $color-white
    );
  }

  &.UIButton--theme-danger {
    @include buttonTheme(
      $newcolor-danger-main,
      $newcolor-danger-dark,
      $newcolor-danger-pale,
      $color-white,
      $newcolor-secondary-light
    );
  }

  &.UIButton--theme-warning {
    @include buttonTheme(
      $newcolor-warning-main,
      $newcolor-warning-dark,
      $newcolor-warning-pale,
      $color-white
    );
  }

  &.UIButton--theme-info {
    @include buttonTheme(
      $newcolor-info-main,
      $newcolor-info-dark,
      $newcolor-info-pale,
      $color-white
    );
  }

  &.UIButton--theme-light {
    @include buttonTheme(
      $newcolor-grey-300,
      $newcolor-grey-400,
      $newcolor-grey-200,
      $color-black
    );

    &.UIButton--style-outlined,
    &.UIButton--style-pale {
      --color: #{$newcolor-grey-600};
    }

    &.UIButton--style-pale {
      &:hover {
        --background: #{$newcolor-grey-600};
        --border-color: #{$newcolor-grey-600};
        --color: #{$color-white};
      }
    }
  }
  &.UIButton--theme-dark {
    @include buttonTheme(
      $newcolor-dark-main,
      $newcolor-dark-light,
      $newcolor-dark-pale,
      $color-white,
      $newcolor-secondary-light
    );
  }

  &.UIButton--theme-option {
    @include buttonTheme(
      $color-white,
      $newcolor-grey-100,
      $newcolor-dark-pale,
      $newcolor-grey-600
    );
    padding: 0.625rem 1.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    border: none;
    width: 100%;

    .UIButton__content {
      justify-content: flex-start;
    }

    &:hover {
      color: $newcolor-primary-main;

      :deep(.UIIcon) {
        --color: #{$newcolor-primary-main};
      }
    }
  }

  &.UIButton--disabled {
    pointer-events: none;
    --background: #{$newcolor-secondary-lighter};
    --border-color: #{$newcolor-grey-300};
    --color: #{$newcolor-grey-400};

    &.UIButton--style-outlined,
    &.UIButton--style-pale {
      --background: #{$newcolor-grey-600};
      --border-color: #{$newcolor-grey-600};
      --color: #{$color-white};
    }
  }
  &.UIButton--is-full {
    width: 100%;
  }

  .UIButton__loader-icon {
    --color: var(--loader-color);
    --size: var(--line-height);
  }
}
</style>
