import { esgTagService } from '@/apiClient/services'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { defineStore } from 'pinia'

export const useEsgTagStore = defineStore('esgTag', () => {
  const socketService = useSocketsCrudFactory(esgTagService, 'esgTag')

  return {
    ...socketService,
  }
})
