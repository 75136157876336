<template>
  <UIDropdown class="LocaleDropdown" :items="allLocaleOptions">
    <UIButtonIcon
      class="LocaleDropdown__icon"
      :icon="localeIcon"
      :key="localeIcon"
    />
  </UIDropdown>
</template>

<script lang="ts" setup>
import UIDropdown from '@/views/components/ui/dropdowns/UIDropdown.vue'
import UIButtonIcon from '../../../buttons/UIButtonIcon.vue'
import { useLocale } from '@/composables/useLocale'

const { allLocaleOptions, localeIcon } = useLocale()
</script>

<style scoped lang="scss">
.LocaleDropdown {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  .LocaleDropdown__icon {
    --size: 1.75rem;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
