import { Paginated, Params, Query, Service } from '@feathersjs/feathers'
import { computed, reactive, Ref, ref, toRefs } from 'vue'
import { useLogger } from './useLogger'
import filterByQuery from '../utils/query'

export type ICrudFactoryConfig = {
  actions?: ('FETCH' | 'GET' | 'CREATE' | 'PATCH' | 'DELETE')[]
}

// TODO: Refactor single and multi items update, create, remove
export function useSocketsCrudFactory<
  T extends {
    id: number
  },
  StashKeys extends Record<string, any> = Record<string, any>,
>(apiService: Service<T>, injectionKey: string | symbol) {
  const { debug, trace } = useLogger(injectionKey?.toString())
  trace('useSocketsCrudFactory')

  const resource = reactive({
    list: ref([]) as Ref<T[]>,
    item: ref<T>(), // TODO: unused for now
  })

  // const reset = () => {
  //   resource.list = []
  //   resource.list = unde
  // }

  //#region Handle socket events
  const onCreatedHandler = (item: T) => {
    debug(`[Socket event][created]`, item)
    resource.list = [item, ...resource.list].sort((a, b) => a.id - b.id)
  }
  const onPatchedHandler = (item: T) => {
    debug(`[Socket event][patched]`, item)
    const index = resource.list.findIndex(
      (resourceItem) => resourceItem.id === item.id,
    )
    if (index !== -1) {
      resource.list[index] = item
    }
  }
  const onRemovedHandler = (item: T) => {
    debug('[Socket event][removed]', item)
    resource.list = resource.list.filter(({ id }) => id !== item.id)
  }

  const initSocketEventHandlers = () => {
    trace('initSocketEventHandlers')
    apiService.on('created', onCreatedHandler)
    apiService.on('patched', onPatchedHandler)
    apiService.on('removed', onRemovedHandler)
  }

  initSocketEventHandlers()
  //#endregion

  /**
   * Bool flags for API calls
   */
  const loadingStates = reactive({
    isFetching: ref(false),
    isCreating: ref(false),
    isPatching: ref(false),
    isDeleting: ref(false),
    isInitialised: ref(false),
  })
  /**
   * Tracks if any API call is pending
   */
  const isLoading = computed(
    () =>
      loadingStates.isFetching ||
      loadingStates.isCreating ||
      loadingStates.isPatching ||
      loadingStates.isDeleting,
  )

  // const { user } = storeToRefs(useUserStore())
  // const orgId = computed(() => user.value?.orgId)
  function checkIfOrgIdPresent() {
    // if (config?.isOrgScope && !orgId.value)
    //   throw new Error('Cannot fetch resource. Missing Org ID.')
  }
  function getQuery(query?: Query) {
    return {
      $sort: {
        id: 1,
      },
      ...(query || {}),
      // ...(config?.isOrgScope && orgId.value ? { orgId: orgId.value } : []),
    }
  }

  // API Calls
  async function fetch(
    query: Query = {
      $sort: {
        id: 1,
      },
    },
  ) {
    checkIfOrgIdPresent()
    loadingStates.isFetching = true

    debug('Fetching...')
    const paginatedResult = await apiService
      .find({ query: getQuery(query) })
      .finally(() => {
        loadingStates.isFetching = false
      })

    debug('Fetching... Done')

    resource.list = paginatedResult.data

    loadingStates.isInitialised = true

    return paginatedResult
  }

  const queriesFetched = ref<string[]>([])
  async function fetchAndAppendIfNeeded(
    query: Query = {
      $sort: {
        id: 1,
      },
    },
  ) {
    loadingStates.isInitialised = true
    const stringifiedQuery = JSON.stringify(query)
    if (queriesFetched.value.includes(stringifiedQuery)) {
      return { data: filterByQuery(resource.list)({ query }) } as Paginated<T>
    }
    queriesFetched.value.push(stringifiedQuery)
    checkIfOrgIdPresent()
    loadingStates.isFetching = true

    debug('Fetching and appending if needed...', query)
    const paginatedResult = await apiService
      .find({ query: getQuery(query) })
      .finally(() => {
        loadingStates.isFetching = false
      })

    resource.list = [
      ...new Map(
        [...resource.list, ...paginatedResult.data].map((item) => [
          item.id,
          item,
        ]),
      ).values(),
    ]

    debug('Fetching and appending if needed... Done', paginatedResult)
    return paginatedResult
  }

  async function find(
    query: Query = {
      $sort: {
        id: 1,
      },
    },
  ) {
    checkIfOrgIdPresent()
    loadingStates.isFetching = true
    debug('Finding...')
    const paginatedResult = await apiService
      .find({ query: getQuery(query) })
      .finally(() => {
        loadingStates.isFetching = false
      })

    debug('Finding... Done')

    return paginatedResult
  }

  async function get(id: number, params?: Params) {
    checkIfOrgIdPresent()
    loadingStates.isFetching = true
    debug('Getting...')
    const result = await apiService.get(id, params).finally(() => {
      loadingStates.isFetching = false
    })
    debug('Getting... Done')
    resource.item = result
    return result
  }

  async function create(data: Partial<T & StashKeys>) {
    checkIfOrgIdPresent()
    loadingStates.isCreating = true
    debug('Creating...')

    try {
      return await apiService.create(data)
    } finally {
      debug('Creating... Done')
      loadingStates.isCreating = false
    }
  }

  async function patch(
    data: Partial<T & StashKeys> | Partial<T & StashKeys>[],
    params?: Params,
  ) {
    checkIfOrgIdPresent()
    loadingStates.isPatching = true
    debug('Patching...')

    try {
      if (Array.isArray(data)) {
        return await patchMulti(data)
      } else {
        return await patchSingle(data, params)
      }
    } finally {
      debug('Patching... Done')
      loadingStates.isPatching = false
    }
  }

  async function patchMulti(data: Partial<T & StashKeys>[]) {
    if (!data.every(({ id }) => id)) {
      throw new Error('Could not patch resource. Missing ID')
    }

    // FeathersJs doesn't support multi patch with different data entries, but it's accepted on BE
    return apiService.patch(null, data as any)
  }
  async function patchSingle(data: Partial<T & StashKeys>, params?: Params) {
    if (!data.id) {
      throw new Error('Could not patch resource. Missing ID')
    }

    return apiService.patch(data.id, data, params)
  }

  async function remove(data: Partial<T> | Partial<T>[]) {
    checkIfOrgIdPresent()
    loadingStates.isDeleting = true

    let response

    try {
      if (Array.isArray(data)) {
        response = await removeMulti(data)
      } else {
        response = await removeSingle(data)
      }

      return response
    } finally {
      loadingStates.isDeleting = false
    }
  }
  async function removeSingle(data: Partial<T>) {
    if (!data.id) {
      throw new Error('Could not remove resource. Missing ID')
    }

    return apiService.remove(data.id)
  }
  async function removeMulti(data: Partial<T>[]) {
    if (data.some(({ id }) => !id)) {
      throw new Error('Could not remove resource. Missing ID')
    }

    return apiService.remove(null, {
      query: { id: { $in: data.map(({ id }) => Number(id)) } },
    })
  }

  return {
    ...toRefs(loadingStates),
    ...toRefs(resource),
    isLoading,
    fetch,
    fetchAndAppendIfNeeded,
    find,
    get,
    create,
    patch,
    remove,
  }
}
