// Sorter taken from feathers (https://github.com/feathersjs/feathers)
// See https://github.com/feathersjs/feathers/blob/master/packages/adapter-commons/src/sort.ts
// Sorting algorithm taken from NeDB (https://github.com/louischatriot/nedb)
// See https://github.com/louischatriot/nedb/blob/e3f0078499aa1005a59d0c2372e425ab789145c1/lib/model.js#L189

const compareNSB = (a: number, b: number) => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }

  return 0
}

const compareArrays = (a: any[], b: any[]): number => {
  let index
  let comp

  for (index = 0; index < Math.min(a.length, b.length); index += 1) {
    comp = compare(a[index], b[index])

    if (comp !== 0) {
      return comp
    }
  }

  // Common section was identical, longest one wins
  return compareNSB(a.length, b.length)
}

const compare = (
  a: Record<string, any>,
  b: Record<string, any>,
  compareStrings = compareNSB,
): number => {
  // undefined
  if (a === undefined) {
    return b === undefined ? 0 : -1
  }
  if (b === undefined) {
    return a === undefined ? 0 : 1
  }

  // null
  if (a === null) {
    return b === null ? 0 : -1
  }
  if (b === null) {
    return a === null ? 0 : 1
  }

  // Numbers
  if (typeof a === 'number') {
    return typeof b === 'number' ? compareNSB(a, b) : -1
  }
  if (typeof b === 'number') {
    return typeof a === 'number' ? compareNSB(a, b) : 1
  }

  // Strings
  if (typeof a === 'string') {
    return typeof b === 'string' ? compareStrings(a, b) : -1
  }
  if (typeof b === 'string') {
    return typeof a === 'string' ? compareStrings(a, b) : 1
  }

  // Booleans
  if (typeof a === 'boolean') {
    return typeof b === 'boolean' ? compareNSB(a, b) : -1
  }
  if (typeof b === 'boolean') {
    return typeof a === 'boolean' ? compareNSB(a, b) : 1
  }

  // Dates
  if (a instanceof Date) {
    return b instanceof Date ? compareNSB(a.getTime(), b.getTime()) : -1
  }
  if (b instanceof Date) {
    return a instanceof Date ? compareNSB(a.getTime(), b.getTime()) : 1
  }

  // Arrays (first element is most significant and so on)
  if (Array.isArray(a)) {
    return Array.isArray(b) ? compareArrays(a, b) : -1
  }
  if (Array.isArray(b)) {
    return Array.isArray(a) ? compareArrays(a, b) : 1
  }

  // Objects
  const aKeys = Object.keys(a).sort()
  const bKeys = Object.keys(b).sort()
  let comp = 0

  for (
    let index = 0;
    index < Math.min(aKeys.length, bKeys.length);
    index += 1
  ) {
    comp = compare(a[aKeys[index]], b[bKeys[index]])

    if (comp !== 0) {
      return comp
    }
  }

  return compareNSB(aKeys.length, bKeys.length)
}

// An in-memory sorting function according to the
// $sort special query parameter
export default ($sort: Record<string, any>) => {
  const criteria = Object.keys($sort).map((key) => {
    const direction = $sort[key]

    return { direction, key }
  })

  return (a: Record<string, any>, b: Record<string, any>) => {
    let compareDirection

    for (const criterion of criteria) {
      compareDirection =
        criterion.direction * compare(a[criterion.key], b[criterion.key])

      if (compareDirection !== 0) {
        return compareDirection
      }
    }

    return 0
  }
}
