<template>
  <div class="UIBlockSubtitle">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const UIBlockSubtitle = defineComponent({
  name: 'UIBlockSubtitle',
})

export default UIBlockSubtitle
</script>

<style scoped lang="scss">
.UIBlockSubtitle {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.66;
}
</style>
