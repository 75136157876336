import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { beforeEach } from './beforeEach'

const baseUrl = import.meta.env.BASE_URL || '/'

const router = createRouter({
  history: createWebHistory(baseUrl),
  routes,
})

// Add hooks
router.beforeEach(beforeEach)

// Add error handler
router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed') ||
    error.message.includes('Unable to preload CSS')
  ) {
    window.location.href = to.fullPath
  }
})

export default router
