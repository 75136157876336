import {
  IEsgParagraph,
  IEsgRequirement,
  IEsgRequirementAnswerConfig,
} from '@/apiClient/types/esg'
import { useLogger } from '@/composables/useLogger'

const fieldComputedVariableRegex = /\{\{([\w-]*?)(\[([0-9]*?)\])?\}\}/g

const logger = useLogger('AnswerDrawer.utils')

export const computeField = (
  field: string | boolean,
  esgRequirementByResourceId: Record<string, IEsgRequirement>,
  injectedFormValues?: Record<string, any>,
  parentInputName?: string,
) => {
  try {
    logger.debug(`[computeField] field`, field)
    logger.debug(`[computeField] injectedFormValues`, injectedFormValues)

    if (typeof field !== 'string') return field

    if (!injectedFormValues) return true

    return eval(
      [...(field as string).matchAll(fieldComputedVariableRegex)].reduce(
        (acc, [match, requirementResourceId, _, answerConfigIndex]) => {
          if (requirementResourceId === 'parentAnswerConfig') {
            const field = parentInputName?.endsWith('.value')
              ? parentInputName
              : `${parentInputName}.value`
            const value = String(
              field.split('.').reduce((accum, path) => {
                return accum?.[path]
              }, injectedFormValues || {}) || 'false',
            )

            return acc.replaceAll(match, value)
          }

          const requirement = esgRequirementByResourceId[requirementResourceId]

          if (!requirement) {
            logger.error(
              `[computeField] Requirement '${requirementResourceId}' cannot be found.`,
            )
          }

          const answerConfigId =
            requirement.answerConfig?.[Number(answerConfigIndex)]?.id

          const returnValue = acc.replaceAll(
            match,
            (answerConfigId &&
              // This makes it work with both form injected values ({reqId.configId: value} format)
              // and projectAnswers (just {configId: value})
              (injectedFormValues[answerConfigId]?.value ||
                injectedFormValues?.[requirement.id]?.[answerConfigId]
                  ?.value)) ||
              'false',
          )

          logger.debug(`[computeField] returnValue`, returnValue)

          return returnValue
        },
        field,
      ),
    )
  } catch (err) {
    logger.error(`[computeField]`, err)
    return false
  }
}

export const getTooltipWithParagraphsHtml = (config: {
  paragraphs?: IEsgParagraph[]
  tooltip?: string
}) => {
  const tooltipArray = []
  const tooltip = config.tooltip || ''

  if (tooltip) {
    tooltipArray.push(tooltip)
  }

  if (tooltip && config?.paragraphs?.length) {
    tooltipArray.push('<br/>')
  }

  if (config?.paragraphs?.length) {
    tooltipArray.push(`<strong>Paragraphs:</strong> ${config.paragraphs
      .map(({ esgStandard, resourceId }) => `${esgStandard}: ${resourceId}`)
      .join(', ')}
    `)
  }

  return tooltipArray.join('')
}
