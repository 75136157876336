<template>
  <div v-if="isVisible && version" class="AppVersion">
    <p>Build: {{ version }}</p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const isVisible = computed(() => import.meta.env.MODE === 'test')
const version = computed(() => import.meta.env.VITE_APP_VERSION)
</script>

<style scoped lang="scss">
.AppVersion {
  position: fixed;
  background-color: $color-white;
  border: 1px solid $color-border;
  border-right: 0;
  border-top-left-radius: 4px;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  bottom: 0;
  right: 0;
}
</style>
