<template>
  <ul v-if="breadcrumbs.length" class="UIBreadcrumbs">
    <li
      class="UIBreadcrumbs_item"
      v-for="({ path, breadcrumb }, index) in breadcrumbs"
      :key="index"
    >
      <UIIcon
        style="margin-right: 0.5rem"
        name="chevronRight"
        v-if="index !== 0"
      />
      <RouterLink
        :class="[
          'UIBreadcrumbs__link',
          {
            'UIBreadcrumbs__link--active': breadcrumbs.length === index + 1,
          },
        ]"
        :to="{ path }"
      >
        {{ breadcrumb }}
      </RouterLink>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import UIIcon from '../icons/UIIcon.vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const route = useRoute()

const { t } = useI18n()

const breadcrumbs = computed(() =>
  route.matched
    .filter(({ path, meta }) => path && meta?.breadcrumb)
    .map(({ path, name, meta }) => ({
      path,
      name,
      breadcrumb: meta.breadcrumb && t(meta.breadcrumb),
    })),
)
</script>

<style scoped lang="scss">
.UIBreadcrumbs {
  font-size: 0.75rem;
  padding: 1.25rem 0.75rem 0 0.75rem;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;

  .UIBreadcrumbs_item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-left: 0.5rem;
  }

  .UIBreadcrumbs__link {
    text-decoration: none;
    font-weight: 500;
    color: $color-grey-700;
  }

  .UIBreadcrumbs__link--active {
    color: $newcolor-grey-400;
    pointer-events: none;
  }
}
</style>
