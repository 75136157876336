import { defineStore, storeToRefs } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { esgRequirementGroupService } from '../../apiClient/services'
import { useEsgRequirementStore } from '../esgRequirement/index'
import { computed } from 'vue'
import { useEsgFrameworkStandardStore } from '../esgFrameworkStandard'
import { createObjectsArrayMap, createObjectsMap } from '@/utils'
import {
  ESG_REQUIREMENT_GROUP_TYPE,
  IEsgFrameworkStandard,
  IEsgRequirementGroup,
} from '@/apiClient/types/esg'

export const useEsgRequirementGroupStore = defineStore(
  'esgRequirementGroup',
  () => {
    const socketService = useSocketsCrudFactory(
      esgRequirementGroupService,
      'esgRequirementGroup',
    )
    // if (!socketService.isInitialised.value) {
    //   socketService.fetch()
    // }

    const { esgRequirementByGroupIdMap, isLoading: isLoadingRequirements } =
      storeToRefs(useEsgRequirementStore())
    const {
      list: esgFrameworkStandards,
      isLoading: isLoadingStandards,
      isInitialised: isInitialisedStandards,
    } = storeToRefs(useEsgFrameworkStandardStore())

    /**
     * Indexed by ID
     * @type {ComputedRef<Record<string, EsgRequirementGroup>>}
     */
    const esgRequirementGroupsMap = computed(() =>
      createObjectsMap(esgRequirementGroups.value, 'id'),
    )

    /**
     * Indexed by ID of the parent requirement group
     * @type {ComputedRef<Record<string, SubrequirementGroup[]>>}
     */
    const subrequirementGroupMap = computed<
      Record<number, IEsgRequirementGroup[]>
    >(() => {
      const subrequirementGroups = socketService.list.value.filter(
        (group) => !!group.parentGroupId,
      )

      const items: IEsgRequirementGroup[] = subrequirementGroups.map(
        (group) => ({
          ...group,
          _requirements: esgRequirementByGroupIdMap.value[group.id] || [],
          _esgFrameworkStandard:
            esgFrameworkStandardsMap.value[group.frameworkStandardId],
          _esgTopic: group?.parentGroupId
            ? esgTopicByIdMap.value[group?.parentGroupId]
            : undefined,
        }),
      )

      return createObjectsArrayMap(items, 'parentGroupId')
    })

    /**
     * ESG Framework Standards, indexed by ID
     * @type {ComputedRef<Record<string, IEsgFrameworkStandard>>}
     */
    const esgFrameworkStandardsMap = computed<
      Record<number, IEsgFrameworkStandard>
    >(() => createObjectsMap(esgFrameworkStandards.value, 'id'))

    /**
     * ESG Topics, indexed by ID
     * @type {ComputedRef<Record<string, EsgTopic>>}
     */
    const esgTopicByIdMap = computed(() =>
      createObjectsMap(
        socketService.list.value.filter(
          ({ type }) => type === ESG_REQUIREMENT_GROUP_TYPE.TOPIC,
        ),
        'id',
      ),
    )

    const esgRequirementGroups = computed<IEsgRequirementGroup[]>(() =>
      socketService.list.value.map((group) => ({
        ...group,
        // _requirements: esgRequirementByGroupIdMap.value[group.id] || [],
        _subGroups: subrequirementGroupMap.value[group.id] || [],
        _esgFrameworkStandard:
          esgFrameworkStandardsMap.value[group.frameworkStandardId],
        _esgTopic: group?.parentGroupId
          ? esgTopicByIdMap.value[group?.parentGroupId]
          : undefined,
      })),
    )

    // const isLoading = computed(
    //   () =>
    //     socketService.isLoading.value ||
    //     isLoadingRequirements.value ||
    //     !isInitialisedStandards.value ||
    //     isLoadingStandards.value,
    // )

    // const isInitialised = computed(
    //   () => socketService.isInitialised.value && isInitialisedStandards.value,
    // )

    return {
      ...socketService,
      esgRequirementGroups,
      esgRequirementGroupsMap,
      // isLoading,
      // isInitialised,
    }
  },
)
