<template>
  <div class="UIChatTypingIndicator">
    <div class="container-dot">
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot"></span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.UIChatTypingIndicator {
  padding-left: 15px;
  display: inline-block;
  background: $newcolor-primary-pale;
  border-radius: 0.4rem 0.4rem 0.4rem 0;
  padding: 0.25rem 1rem 0.5rem 1rem;
  margin-left: 0.75rem;
  .dot {
    height: 0.35rem;
    width: 0.35rem;
    border-radius: 100%;
    display: inline-block;
    background-color: $newcolor-primary-light;
    animation: 1.2s typing-dot ease-in-out infinite;
    margin-right: 0.1rem;
  }
  .dot:nth-of-type(2) {
    animation-delay: 0.15s;
  }
  .dot:nth-of-type(3) {
    animation-delay: 0.25s;
  }
  @keyframes typing-dot {
    15% {
      transform: translateY(-35%);
      opacity: 0.5;
    }
    30% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}
</style>
