import { userSettingService } from '@/apiClient/services'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { USER_SETTING_KEY } from '@/apiClient/types/user'
import { createPropertyMap } from '@/utils'
import { useUserStore } from '../user'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useUserSettingStore = defineStore('userSettingStore', () => {
  const crud = useSocketsCrudFactory(userSettingService, 'userSetting')

  const userStore = useUserStore()

  const userSettingsMap = computed(
    () =>
      createPropertyMap(crud.list.value, 'key', 'value') as Record<
        USER_SETTING_KEY,
        string
      >,
  )

  const setUserSetting = async (
    settingKey: USER_SETTING_KEY,
    value: string,
  ) => {
    if (!userStore.user?.id) return

    if (Object.keys(userSettingsMap.value).includes(settingKey)) {
      const currentUserSetting = crud.list.value.find(
        ({ key }) => key === settingKey,
      )
      await crud.patch({
        id: currentUserSetting?.id,
        key: settingKey,
        value: value ? String(value) : null,
        userId: userStore.user?.id,
      })
    } else {
      await crud.create({
        key: settingKey,
        value: value ? String(value) : null,
        userId: userStore.user?.id,
      })
    }
  }

  return {
    ...crud,
    userSettings: crud.list,
    userSettingsMap,
    setUserSetting,
  }
})
