export function sortValuesByArray<T>(
  arr: T[],
  sortValues: unknown[],
  property?: keyof T,
): T[] {
  return arr.sort((a: T, b: T) => {
    const aIndex = sortValues.indexOf(property ? a[property] : a)
    const bIndex = sortValues.indexOf(property ? b[property] : b)

    return aIndex - bIndex
  })
}
