import { _ } from '@feathersjs/commons'
const { isObject, isObjectOrArray } = _

import sift, { createEqualsOperation } from 'sift'
import sorter from './sorter'
import { Params } from '@feathersjs/feathers'
import { Options } from 'sift/lib/core'

// TODO: replace all `any` types

const isNil = (value: any) => value !== '' || value != undefined

interface Dictionary {
  [key: string]: number
}

function map(
  func: (value: number) => number,
  data: number[] | Dictionary,
): number[] | Dictionary {
  if (Array.isArray(data)) {
    return data.map(func)
  } else if (typeof data === 'object' && data !== null) {
    const dictionaryData = data as Dictionary
    return Object.keys(dictionaryData).reduce((acc: Dictionary, key) => {
      acc[key] = func(dictionaryData[key])
      return acc
    }, {})
  } else {
    throw new Error('Unsupported data type. Expected array or object.')
  }
}

const customExpressions = {
  $like: (queryValue: any, ownerQuery: any, options: Options) =>
    createEqualsOperation(
      (fieldValue: any) => fieldValue && fieldValue.includes(queryValue),
      ownerQuery,
      options,
    ),

  $ilike: (queryValue: any, ownerQuery: any, options: Options) => {
    const lowercaseValue = queryValue.toLowerCase()

    return createEqualsOperation(
      (fieldValue: any) =>
        fieldValue && fieldValue.toLowerCase().includes(lowercaseValue),
      ownerQuery,
      options,
    )
  },

  $contained: (queryValue: any[], ownerQuery: any, options: Options) =>
    createEqualsOperation(
      (fieldValue: any[]) =>
        fieldValue && fieldValue.every((item) => queryValue.includes(item)),
      ownerQuery,
      options,
    ),

  $contains: (queryValue: any[], ownerQuery: any, options: Options) =>
    createEqualsOperation(
      (fieldValue: any) =>
        fieldValue && queryValue.every((item) => fieldValue.includes(item)),
      ownerQuery,
      options,
    ),

  $overlap: (queryValue: any[], ownerQuery: any, options: Options) =>
    createEqualsOperation(
      (fieldValue: any) =>
        fieldValue && queryValue.some((item) => fieldValue.includes(item)),
      ownerQuery,
      options,
    ),
}

// wrap $like and $ilike in %
const wrapInPercent = (() => {
  const wrapValue = (() => {
    const fieldsToWrap = new Set(['$ilike', '$like', '$notilike', '$notlike'])
    const excludeFromWrap = new Set([
      '$bounds',
      '$contained',
      '$contains',
      '$in',
      '$nin',
      '$overlap',
    ])

    return (value: any, key?: string): any => {
      if (typeof key !== 'undefined' && excludeFromWrap.has(key)) {
        return value
      }

      if (isObjectOrArray(value)) {
        if (Array.isArray(value)) return value.map(wrapInPercent)
        if (value instanceof Date) return value.toISOString()
        return wrapInPercent(value)
      }

      if (
        typeof value !== 'string' ||
        value.startsWith('%') ||
        value.endsWith('%') ||
        !(key && fieldsToWrap.has(key))
      ) {
        return value
      }

      return `%${value}%`
    }
  })()

  return (cleanQuery: any) => {
    const wrapper = Array.isArray(cleanQuery) ? wrapInPercent : wrapValue

    return map(wrapper, cleanQuery)
  }
})()

export function buildServerQuery(query: any) {
  // TODO: make it deep
  // remove undefined values to allow using `queryParam || undefined` shorthand
  const { $server, ...cleanQuery } = Object.fromEntries(
    Object.entries(query).filter(([_, value]) => typeof value !== 'undefined'),
  )

  return wrapInPercent({
    ...cleanQuery,
    ...($server || {}),
  })
}

const Query = {
  _find(list: any[], params: any) {
    if (typeof params === 'number') {
      return list[params]
    } else if (typeof list[params] === 'string' && !isNaN(params)) {
      return list[parseInt(params, 10)]
    }

    const { $fetch, ...preQuery } = (params || {}).query || {}
    const { $skip, $limit, $sort, $select, ...query } = preQuery

    let values = Object.values(list).filter(
      sift(query, {
        operations: customExpressions,
      }),
    )

    if ($sort) {
      values.sort(typeof $sort === 'function' ? $sort : sorter($sort))
    }

    if ($skip) {
      values = values.slice($skip)
    }

    if (typeof $limit !== 'undefined') {
      values = values.slice(0, $limit)
    }

    if ($select) {
      values = values.map((value) => _.pick(value, ...$select))
    }

    return values
  },

  find(list: any[], params: any = {}) {
    if (isObject(params) && !params.query) {
      params = {
        query: params,
      }
    }

    // Call the internal find with query parameter that include pagination
    return this._find(list, params)
  },
}

export default (list: any[]) =>
  (params: Params, options?: { isSingle: boolean }) => {
    const isSingle = options && options.isSingle
    const result = Query.find(list, params)

    if (isSingle === true) {
      if (result && result.length) {
        return result[0]
      } else if (isObject(result)) {
        return result
      }

      return null
    }

    return result
  }

export const pruneQuery = (() => {
  const { fromEntries, entries } = Object
  const empty = new Set(['$contained', '$contains', '$in', '$nin', '$overlap'])
  const nulls = new Set(['$gt', '$gte', '$lt', '$lte'])

  const pruneQueryParameter = ([key, value]: [string, any]) => {
    if (empty.has(key) && !value.length) return
    if (nulls.has(key) && isNil(value)) return

    return [key, value]
  }

  return (query: any) =>
    fromEntries(
      entries(query)
        .map(([key, value]: [string, any]) => {
          if (value === undefined) return
          if (!isObject(value)) return [key, value]

          const pruned: any = entries(value)
            .map(pruneQueryParameter)
            .filter(Boolean)

          if (!pruned.length) return

          return [key, fromEntries(pruned)]
        })
        .filter(Boolean) as any[],
    )
})()
