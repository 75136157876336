import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import lt from './locales/lt.json'

export type MessageSchema = typeof en
export type MessagesKeys = keyof MessageSchema

export enum LOCALES {
  EN = 'en',
  LT = 'lt',
}

export const i18n = createI18n<[MessageSchema], LOCALES>({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  fallbackWarn: false,
  missingWarn: false,
  warnHtmlMessage: false,
  messages: {
    en,
    lt,
  },
})
