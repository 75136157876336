<template>
  <ul class="ThePlatformSelector">
    <li>
      <RouterLink
        class="ThePlatformSelector__logo"
        :to="{ name: homeRouteName }"
      >
        <img src="/logo-icon.svg" />
      </RouterLink>
    </li>
    <ThePlatformSelectorItem
      v-for="item in itemsFiltered"
      :key="item.icon"
      :item="item"
    />
    <ThePlatformSelectorItem
      class="ThePlatformSelector__settings"
      :item="{
        to: { name: settingRouteName },
        icon: 'cog',
      }"
      @close="$emit('close')"
    />
  </ul>
</template>

<script setup lang="ts">
import { RouterLinkProps } from 'vue-router'
import { IIcons } from '@/views/components/ui/icons/types'
import ThePlatformSelectorItem from './ThePlatformSelectorItem.vue'
import { useSidebarItems } from '../../composables/useSidebarItems'
import { computed } from 'vue'
import { FeatureFlag } from '@/apiClient/types/featureFlag'
import { useFeatureFlagStore } from '@/store/featureFlag'
import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

const { isEsg, settingRouteName } = useSidebarItems()
const { hasFeature } = useFeatureFlagStore()
const { hasAllCompaniesAccess } = storeToRefs(useUserStore())

const homeRouteName = computed(() => (isEsg.value ? 'ESG' : 'CO2'))

export type IThePlatformSelectorItem = {
  to?: RouterLinkProps['to']
  icon: IIcons
  isHidden?: boolean
}

const items = computed<IThePlatformSelectorItem[]>(() => [
  {
    icon: 'moleculeCo2',
    to: { name: 'CO2' },
    isHidden: !hasFeature(FeatureFlag.ghgPlatform),
  },
  {
    icon: 'leafCircleOutline',
    to: { name: 'ESG' },
    isHidden:
      !hasFeature(FeatureFlag.esgPlatform) || !hasAllCompaniesAccess.value,
  },
])
const itemsFiltered = computed(() => {
  const filtered = items.value.filter((item) => !item.isHidden)

  if (filtered.length > 1) {
    return filtered
  }

  return []
})
</script>

<style lang="scss" scoped>
.ThePlatformSelector {
  display: flex;
  flex-direction: column;
  width: 5rem;
  background: $color-primary-main;
  border-right: 1px solid $color-primary-main;
  position: relative;
  max-height: 100vh;

  &__logo {
    padding: 1.25rem 1.5rem 1.25rem;
    display: flex;
    justify-content: center;

    img {
      width: 2rem;
    }
  }

  &__settings {
    position: absolute;
    bottom: 0.5rem;

    :deep(.UIIcon) {
      --size: 1.25rem;
    }
  }
}
</style>
