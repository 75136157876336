<template>
  <UIChat
    :class="['TheAssistantChat', { 'TheAssistantChat--margin': isTawkEnabled }]"
    :chatItems="chatItems"
    :isLoading="isLoading"
    @sendMessage="sendMessage"
  >
    <template #header>
      <UIIcon name="robotHappy" />
      <div class="TheAssistantChat__title">
        <span>Sust</span>AI<span>nalyse</span>
      </div>
    </template>
  </UIChat>
</template>

<script lang="ts" setup>
import UIIcon from '@/views/components/ui/icons/UIIcon.vue'
import UIChat from '@/views/components/ui/chats/UIChat'
import { computed, onMounted } from 'vue'
import { ASSISTANT_CHAT_ROLE } from '@/apiClient/types/assistantChat'
import { useAssistantLlama } from '@/composables/useAssistantLlama'
import { IChatItem } from '../UIChat/components/UIChatArea.vue'
import { isTawkEnabled } from '@/config'

const { list, create, isLoading } = useAssistantLlama()

const chatItems = computed<IChatItem[]>(() =>
  list.value.map((item) => ({
    chatLineType:
      item.role === ASSISTANT_CHAT_ROLE.ASSISTANT ? 'response' : 'message',
    content: cleanText(item.content),
  })),
)

const sendMessage = async (message: string, callBack?: () => void) => {
  // GPT don't like the intro
  const chatHistory = [...list.value.slice(1)]
  list.value.push({
    role: ASSISTANT_CHAT_ROLE.USER,
    content: message,
  })

  const response = await create({
    content: message,
    chatHistory,
  })

  list.value.push({
    role: ASSISTANT_CHAT_ROLE.ASSISTANT,
    content: response.content,
  })

  callBack?.()
}

const cleanText = (text: string) =>
  text
    .replaceAll('\n', '<br>')
    .replaceAll(/【.*?】/g, '')
    .replaceAll('Observation: ', '')

onMounted(() => {
  if (!list.value.length) {
    list.value.push({
      role: ASSISTANT_CHAT_ROLE.ASSISTANT,
      content: "Hello! I'm GHG Assistant, how can I assist you today?",
    })
  }
})
</script>

<style lang="scss" scoped>
.TheAssistantChat {
  // Overflow tawk.io chat
  z-index: 2000000001;
  &--margin {
    margin-right: 5rem;
    margin-bottom: 0.5rem;
  }

  .UIIcon-wrapper {
    margin-right: 0.4rem;
    --size: 1.4rem;
  }

  &__title {
    span {
      font-size: 0.9125rem;
      font-weight: 600;
      margin-right: 0.125rem;
      margin-left: 0.075rem;
    }
  }
}
</style>
