<template>
  <transition name="slide-fade">
    <aside v-if="isSidebarVisible" class="TheSidebar">
      <ThePlatformSelector />
      <div class="TheSidebar__menu">
        <TheSidebarTitle>
          {{ title }}
        </TheSidebarTitle>

        <TheSidebarMenuGroup
          v-for="(group, index) in menuItemGroups"
          :key="index"
        >
          <template
            v-for="(item, itemIndex) in group.items"
            :key="`${item.label}_${itemIndex}`"
          >
            <TheSidebarMenuItem
              v-if="!item.isHidden"
              :item="item"
              @click="toggleChildren(`${item.label}_${itemIndex}`)"
              @close="
                item.children && item.children.length ? null : $emit('close')
              "
            >
              <template #toggleIcon>
                <UIIcon
                  name="chevronRight"
                  v-if="item.children && item.children.length"
                  class="TheSidebar__toggle-icon"
                  :class="{
                    'TheSidebar__toggle-icon--toggled':
                      childrenVisibility[`${item.label}_${itemIndex}`],
                  }"
                />
              </template>
            </TheSidebarMenuItem>

            <!-- Handle children -->
            <transition name="slide-y-transition">
              <TheSidebarMenuGroup
                v-if="childrenVisibility[`${item.label}_${itemIndex}`]"
              >
                <template
                  v-for="(child, childIndex) in item.children"
                  :key="`${child.label}_${childIndex}`"
                >
                  <TheSidebarMenuItem
                    v-if="!child.isHidden"
                    :isChild="true"
                    :item="child"
                    @close="$emit('close')"
                  />
                </template>
              </TheSidebarMenuGroup>
            </transition>
          </template>
        </TheSidebarMenuGroup>
        <!-- <TheSidebarMenuGroup class="TheSidebar__logout">
          <TheSidebarMenuItem
            :item="{
              label: t('settings'),
              to: { name: settingRouteName },
              icon: 'cog',
            }"
            @close="$emit('close')"
          />
        </TheSidebarMenuGroup> -->
      </div>
    </aside>
  </transition>

  <transition name="fade">
    <div
      v-if="isSidebarVisible"
      class="TheSidebar__overlay"
      @click="$emit('close')"
    />
  </transition>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue'
import TheSidebarMenuGroup from './components/TheSidebarMenuGroup.vue'
import TheSidebarMenuItem from './components/TheSidebarMenuItem.vue'
import TheSidebarTitle from './components/TheSidebarTitle.vue'
import { useRoute } from 'vue-router'
import { useWindowSize } from '@vueuse/core'
import UIIcon from '../../icons/UIIcon.vue'
import ThePlatformSelector from './components/ThePlatformSelector/ThePlatformSelector.vue'
import { useSidebarItems } from './composables/useSidebarItems'

const emit = defineEmits(['close'])
const props = defineProps({
  isOpen: { type: Boolean, default: true },
})

const isSidebarVisible = computed(
  () => !isMobileView.value || (isMobileView.value && props.isOpen),
)

const { menuItemGroups, title } = useSidebarItems()

const childrenVisibility = ref<Record<string, boolean>>({})
const toggleChildren = (key: string) => {
  childrenVisibility.value[key] = !childrenVisibility.value[key]
}
const route = useRoute()
onMounted(() => {
  const matchedRouteNames = route.matched.map(({ name }) => name)
  // Open child menu group on refresh
  menuItemGroups.value.forEach((group) => {
    group.items.forEach((item, itemIndex) => {
      if (!item.children) return
      item.children.forEach((child) => {
        if (child.to && matchedRouteNames.includes((child.to as any).name)) {
          childrenVisibility.value[`${item.label}_${itemIndex}`] = true
        }
      })
    })
  })
})

const { width } = useWindowSize()
const isMobileView = computed(() => width.value < 960)
watch(isMobileView, (isMobile) => {
  if (isMobile && props.isOpen) {
    emit('close')
  }
})
</script>

<style lang="scss" scoped>
.TheSidebar {
  display: flex;
  position: fixed;
  z-index: 100;
  width: $sidebar-width;
  height: 100%;

  @include md {
    min-width: $sidebar-width;
  }
  .TheSidebar__menu {
    @include customScrollbar;

    font-family: 'Nunito';
    border-right: 1px solid $newcolor-border-light;
    // position: fixed;
    width: 100%;
    box-shadow: $box-shadow-1;
    padding: 1.25rem 1.25rem 2.5rem;
    background-color: $color-white;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include md {
      padding: 1.5rem 1.75rem 2rem;
    }

    .TheSidebar__title {
      text-decoration: none;
      color: inherit;
    }

    .TheSidebar__title-logo {
      width: 100%;
    }

    .TheSidebar__toggle-icon {
      @include trans(0.3s);
      --size: 1.25rem !important;

      &--toggled {
        transform: rotate(90deg);
      }
    }

    // .TheSidebar__logout {
    //   margin-top: auto;
    // }
  }
}

.TheSidebar__overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: $color-overlay;
  z-index: 99;

  @include md {
    display: none;
  }
}
</style>
