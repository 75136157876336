import { defineStore } from 'pinia'
import { ref } from 'vue'
import { nanoid } from 'nanoid'
import {
  IToast,
  IToastOptions,
  TOAST_TYPE,
} from '@/views/components/ui/toasts/types'

export const useToast = defineStore('toast', () => {
  const toasts = ref<IToast[]>([])

  const addToast = (message: string, options: IToastOptions = {}) => {
    const toastId = nanoid()
    toasts.value.push({
      id: toastId,
      message,
      ...options,
      type: options.type || TOAST_TYPE.INFO,
    })
    if (options.timeout === 'none') return

    setTimeout(() => {
      removeToast(toastId)
    }, options.timeout || 5000)

    return toastId
  }

  const removeToast = (toastId: string) => {
    toasts.value = toasts.value.filter(({ id }) => id !== toastId)
  }

  const onSuccess = (message: string, options: IToastOptions = {}) =>
    addToast(message, {
      type: TOAST_TYPE.SUCCESS,
      ...options,
    })
  const onError = (message: string, options: IToastOptions = {}) =>
    addToast(message, {
      type: TOAST_TYPE.ERROR,
      ...options,
    })
  const onInfo = (message: string, options: IToastOptions = {}) =>
    addToast(message, {
      type: TOAST_TYPE.INFO,
      ...options,
    })

  const clearToasts = () => {
    toasts.value = []
  }
  const onWarning = (message: string, options: IToastOptions = {}) =>
    addToast(message, {
      type: TOAST_TYPE.WARNING,
      ...options,
    })

  return {
    onSuccess,
    onError,
    onInfo,
    onWarning,
    toasts,
    removeToast,
    clearToasts,
  }
})
