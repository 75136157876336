<template>
  <h5 class="TheSidebarTitle">
    <slot />
  </h5>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.TheSidebarTitle {
  margin: 0.325rem 0 1.5rem;
  font-size: 1.15rem;

  @include md {
    font-size: 1.25rem;
  }
}
</style>
