import { apiBase, apiOrigin, isProduction } from '../config'
import io from 'socket.io-client'
import socketio from '@feathersjs/socketio-client'
import { Application } from '@feathersjs/feathers'

const config = {
  transports: ['websocket'],
  path: `${apiBase}/ws/`,
  secure: isProduction,
}

export const socket = io(apiOrigin, config)

export default function socketClient(app: Application) {
  app.configure(
    socketio(socket, {
      timeout: 250000,
    }),
  )

  // forces to clear out user connection data
  app.on('logout', () => {
    socket.disconnect()
    socket.connect()
  })
}
