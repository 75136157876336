import { debugLevel } from '@/config'

export const useLogger = (namespace: string) => {
  enum LogType {
    TRACE = 'TRACE',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
    FATAL = 'FATAL',
  }

  const LogTypes = Object.values(LogType)
  const getConsoleStyles = (style: { background: string; color: string }) => [
    `font-style: italic; font-weight: bold; background: ${style.background}; color: ${style.color}`,
    `font-weight: bold; background: ${style.background}; color: ${style.color}`,
    '',
  ]
  const consoleLogByType = {
    [LogType.TRACE]: (message: string, ...args: any[]) => {
      console.debug(
        message,
        ...getConsoleStyles({ background: 'lightblue', color: 'navy' }),
        ...args,
      )
    },
    [LogType.DEBUG]: (message: string, ...args: any[]) =>
      console.debug(
        message,
        ...getConsoleStyles({ background: 'lightcyan', color: 'darkcyan' }),
        ...args,
      ),
    [LogType.INFO]: (message: string, ...args: any[]) =>
      console.info(
        message,
        ...getConsoleStyles({ background: 'lightgreen', color: 'darkgreen' }),
        ...args,
      ),
    [LogType.WARN]: (message: string, ...args: any[]) =>
      console.warn(
        message,
        ...getConsoleStyles({ background: 'lightyellow', color: 'darkorange' }),
        ...args,
      ),
    [LogType.ERROR]: (message: string, ...args: any[]) =>
      console.error(
        message,
        ...getConsoleStyles({ background: 'lightcoral', color: 'darkred' }),
        ...args,
      ),
    [LogType.FATAL]: (message: string, ...args: any[]) =>
      console.error(
        message,
        ...getConsoleStyles({ background: 'pink', color: 'darkred' }),
        ...args,
      ),
  }

  const log = (type: LogType) => {
    const logLevelIndex = LogTypes.indexOf(
      (localStorage.getItem('LOG_LEVEL')?.toUpperCase() ||
        debugLevel) as LogType,
    )

    return (...args: any[]) => {
      if (!(logLevelIndex > -1 && LogTypes.indexOf(type) >= logLevelIndex)) {
        return
      }
      consoleLogByType[type](
        `%c[${namespace}] %c${type.toUpperCase()}:%c`,
        ...args,
      )
      if (type === LogType.FATAL) {
        throw new Error(`[${namespace}] ${type.toUpperCase()}: `, ...args)
      }
    }
  }

  return {
    trace: log(LogType.TRACE),
    debug: log(LogType.DEBUG),
    info: log(LogType.INFO),
    warn: log(LogType.WARN),
    error: log(LogType.ERROR),
  }
}
