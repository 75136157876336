export enum TOAST_POSITION {
  TOP_RIGHT = 'top-right',
  TOP_LEFT = 'top-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
}

export enum TOAST_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export enum TOAST_ICONS {
  SUCCESS = 'checkCircle',
  ERROR = 'closeCircle',
  INFO = 'alertCircle',
}

export type IToastOptions = {
  title?: string
  type?: TOAST_TYPE
  timeout?: number | 'none'
}

export interface IToast extends IToastOptions {
  id: string
  message: string
  type: TOAST_TYPE
}
