import { unitService, unitMultiplierService } from '@/apiClient/services'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { IUnit, IUnitMultiplier } from '@/apiClient/types/unit'
import { createObjectsMap, groupByKey, sortValuesByArray } from '@/utils'
import { useI18n } from 'vue-i18n'
import { IUIInputSelectOptionObject } from '@/views/components/ui/inputs/UIInputSelect'

export const useUnitStore = defineStore('unitStore', () => {
  const units = ref<IUnit[]>([])
  const unitMultipliers = ref<IUnitMultiplier[]>([])

  const unitMultipliersSorted = computed(() =>
    sortValuesByArray(
      unitMultipliers.value,
      ['MWh', 'kWh', 'GWh', 'MJ', 'GJ', 'TJ'],
      'name',
    ),
  )

  const { t } = useI18n()

  const unitsTranslated = computed(() =>
    units.value.map((unit) => ({
      ...unit,
      key: unit.name,
      name: t(unit.name),
    })),
  )

  const unitOptions = computed(() =>
    unitsTranslated.value.map(({ id, name }) => ({ title: name, value: id })),
  )

  const unitMultipliersByUnitId = computed<Record<string, IUnitMultiplier[]>>(
    () => groupByKey('unitId', unitMultipliers.value),
  )
  const unitMultiplierOptionsByUnitName = computed<
    Record<string, IUIInputSelectOptionObject[]>
  >(() =>
    unitsTranslated.value.reduce(
      (accum, unit) => ({
        ...accum,
        [unit.key]: [
          { title: unit.name, value: 1 },
          ...(unitMultipliersByUnitId.value[unit.id]?.map(
            ({ name, multiplier }) => ({
              title: name,
              value: multiplier,
            }),
          ) || []),
        ],
      }),
      {},
    ),
  )

  const unitIdMap = computed(() => createObjectsMap(units.value, 'id'))

  const unitMultipliersMap = computed(() =>
    createObjectsMap(unitMultipliers.value, 'id'),
  )

  const fetchAllUnits = () => {
    unitService.find().then((result) => {
      units.value = result.data
    })
  }
  const fetchAllUnitMultipliers = () => {
    unitMultiplierService.find().then((result) => {
      unitMultipliers.value = result.data
    })
  }
  const fetchAll = () => {
    return Promise.all([fetchAllUnits(), fetchAllUnitMultipliers()])
  }

  return {
    units: unitsTranslated,
    unitOptions,
    unitMultiplierOptionsByUnitName,
    unitIdMap,
    unitMultipliers: unitMultipliersSorted,
    unitMultipliersMap,
    fetchAllUnits,
    fetchAllUnitMultipliers,
    fetchAll,
  }
})
