import { defineStore } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { productLineService } from '../../apiClient/services'
import { computed } from 'vue'
import { createObjectsArrayMap, createObjectsMap } from '@/utils'

export const useProductLineStore = defineStore('productLine', () => {
  const socketService = useSocketsCrudFactory(productLineService, 'productLine')

  /**
   * Facilities, indexed by their companyId
   */
  const productLinesByCompanyIdMap = computed(() =>
    createObjectsArrayMap(socketService.list.value, 'companyId'),
  )

  /**
   * Facilities, indexed by their ID
   */
  const productLinesByIdMap = computed(() =>
    createObjectsMap(socketService.list.value, 'id'),
  )

  const productLines = computed(() => socketService.list.value)

  return {
    ...socketService,
    productLines,
    productLinesByIdMap,
    productLinesByCompanyIdMap,
  }
})
