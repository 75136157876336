import { RouteLocationNormalized } from 'vue-router'
import { computed } from 'vue'
import { useUserStore } from '@/store/user'
import { UserRoleGroups, UserRoles } from '@/apiClient/types/user'
import { isAuthRoute } from './hooks/helpers/isAuthRoute'
import { useFeatureFlagStore } from '@/store/featureFlag'
import { FeatureFlag } from '@/apiClient/types/featureFlag'
import client from '@/apiClient'

const userStore = computed(() => useUserStore())
const user = computed(() => userStore.value.user)
const userRole = computed(() => user.value?.role)
const redirectToOrgRegistration = computed(
  () => userRole.value === UserRoles.ORGADMIN && !user.value?.orgId,
)
const redirectAwayFromOrgRegistration = computed(
  () =>
    (userRole.value === UserRoles.ORGADMIN ||
      (userRole.value &&
        UserRoleGroups.ADVANCED_USER_AND_UNDER.includes(userRole.value))) &&
    !!user.value?.orgId,
)

// Auth
const checkIsLoggedIn = computed(() => useUserStore().isLoggedIn)

// Role access
const hasAccess = (to: RouteLocationNormalized) => {
  if (!isAuthRoute(to)) return true

  if (!userRole.value) return false

  const allRouteRoles = to.matched
    .map((route) => route.meta?.role || [])
    .filter((roles) => roles.length)

  return (
    allRouteRoles.every((roles) =>
      roles.includes(userRole.value as UserRoles),
    ) &&
    to.matched.every(
      ({ meta }) =>
        !meta.onlyWithAllOrgAccess ||
        (meta.onlyWithAllOrgAccess && userStore.value.hasAllCompaniesAccess),
    )
  )
}

// Feature flags
const hasFeatureAccess = (to: RouteLocationNormalized) => {
  const featureFlagStore = useFeatureFlagStore()

  const allRouteFeatureFlags = to.matched
    .map((route) => route.meta.featureFlag)
    .filter(Boolean)

  return allRouteFeatureFlags.every((key: FeatureFlag) =>
    featureFlagStore.hasFeature(key),
  )
}

export const beforeEach = async (to: RouteLocationNormalized) => {
  // Feature flags
  const featureFlagStore = useFeatureFlagStore()

  try {
    // //////////////////////
    // Auth
    // //////////////////////
    // Check for user auth
    if (!checkIsLoggedIn.value) {
      // Try to reauthenticate
      try {
        await useUserStore().reAuthenticate()
      } catch (e) {
        if (isAuthRoute(to)) {
          return { name: 'Login' }
        }
      }
    }

    // //////////////////////
    // Feature flags
    // //////////////////////
    // Check if feature flags loaded
    if (!featureFlagStore.isFeatureFlagsLoaded) {
      await featureFlagStore.fetch()
    }

    // Logout if reset token is provided
    if (checkIsLoggedIn.value && to.name === 'LoginReset' && !!to.query.token) {
      await client.logout()
      useUserStore().setUser()
    }

    // Disallow user to access public pages
    else if (!isAuthRoute(to) && checkIsLoggedIn.value) {
      // Ignore logout page
      if (to.name !== 'Logout') {
        return { name: 'LayoutDashboard' }
      }
    }

    ////////////////////////
    // Role access
    ////////////////////////
    if (!hasAccess(to))
      throw new Error(
        '[Role access] User does not have sufficient priveleges to access the page.',
      )

    ////////////////////////
    // Default redirect
    ////////////////////////
    if (!to.matched.filter(({ name }) => name !== 'LayoutDashboard').length) {
      if (featureFlagStore.hasFeature(FeatureFlag.ghgPlatform)) {
        return { name: 'AnalyticsTabOverview' }
      }

      if (featureFlagStore.hasFeature(FeatureFlag.esgPlatform)) {
        return { name: 'EsgDashboard' }
      }
    }

    ////////////////////////
    // Feature flag
    ////////////////////////
    if (!hasFeatureAccess(to))
      throw new Error(
        '[hasFeatureAccess] User does not have sufficient priveleges to access the page.',
      )

    ////////////////////////
    // Registration flow
    ////////////////////////
    if (to.name !== 'RegisterFinalisation' && redirectToOrgRegistration.value) {
      return { name: 'RegisterFinalisation' }
    } else if (
      to.name === 'RegisterFinalisation' &&
      redirectAwayFromOrgRegistration.value
    ) {
      return { name: 'LayoutDashboard' }
    }
  } catch (err) {
    if (checkIsLoggedIn.value) {
      return { name: 'LayoutDashboard' }
    }

    return { name: 'Login' }
  }
}
