<template>
  <div :class="`UIChatItem UIChatItem--${chatItem.chatLineType}`">
    <div
      :class="`UIChatItem__message-${chatItem.chatLineType}`"
      v-html="chatItemTransformed.content"
    />
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { IChatItem } from './UIChatArea.vue'

const props = defineProps({
  chatItem: {
    type: Object as PropType<IChatItem>,
    required: true,
  },
  justifyContent: {
    type: String,
    default: 'space-between',
  },
})

const chatItemTransformed = computed(() => {
  const { chatItem } = props
  return {
    ...chatItem,
    content: chatItem.content.replace(/\n/g, '<br />'),
  }
})
</script>

<style lang="scss" scoped>
.UIChatItem {
  width: 100%;
  display: flex;
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;

  &--message {
    justify-content: flex-end;
  }
  &--response {
    justify-content: flex-start;
  }

  .UIChatItem__message-message {
    background: $newcolor-primary-light;
    color: white;
    padding: 0.5rem;
    border-radius: 0.4rem 0.4rem 0 0.4rem;
    margin-left: 2rem;
  }

  .UIChatItem__message-response {
    background: $newcolor-primary-pale;
    border-radius: 0.4rem 0.4rem 0.4rem 0;
    padding: 0.5rem;
    margin-right: 2rem;
  }

  :deep(ul) {
    list-style-position: inside;
  }
}
</style>
