import {
  ITrackingTemplateSource,
  IEmissionTrackingTemplateWithItems,
  ITrackingTemplateSourceData,
} from './../../apiClient/types/emissions'
import { useEmissionSourceStore } from './../emissionSource/index'
import { useEmissionTrackingTemplateSourceStore } from '../trackingTemplateSource/index'
import { emissionTrackingTemplateService } from '@/apiClient/services'
import {
  IEmissionSource,
  IEmissionTrackingTemplateForm,
} from '@/apiClient/types/emissions'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useEmissionTrackingTemplateStore = defineStore(
  'trackingTemplate',
  () => {
    const templateSourceStore = useEmissionTrackingTemplateSourceStore()
    const emissionSourceStore = useEmissionSourceStore()

    const crud = useSocketsCrudFactory(
      emissionTrackingTemplateService,
      'trackingTemplate',
    )

    const templateOptions = computed(() =>
      crud.list.value.map((item) => ({
        title: item.name,
        value: item.id,
        raw: item,
      })),
    )

    const emissionSourcesGroupedByTemplateId = computed<
      Record<number, WithId<IEmissionSource>[]>
    >(() =>
      templateSourceStore.emissionTrackingTemplateSources.reduce(
        (accum: any, templateSource) => {
          const templateId = templateSource.trackingTemplateId
          if (!templateId) return accum

          const emissionSource = emissionSourceStore.emissionSources.find(
            ({ id }) => id === templateSource.emissionSourceId,
          )

          return {
            ...accum,
            [templateId]: [...(accum[templateId] || []), emissionSource],
          }
        },
        [],
      ),
    )

    const templatesWithItems = computed<IEmissionTrackingTemplateWithItems[]>(
      () =>
        crud.list.value.reduce(
          (accum: IEmissionTrackingTemplateWithItems[], template) => {
            return [
              ...accum,
              {
                ...template,
                emissionSources:
                  emissionSourcesGroupedByTemplateId.value[template.id] || [],
              },
            ]
          },
          [],
        ),
    )

    const fetchAll = async () => {
      const templates = await crud.fetch()
      const templatesIds = templates.data.map(({ id }) => id).filter(Boolean)
      if (!templatesIds.length) return
      await Promise.all(
        [templateSourceStore].map(({ fetch }) =>
          fetch({
            trackingTemplateId: { $in: templatesIds },
          }),
        ),
      )
    }

    const create = async (template: IEmissionTrackingTemplateWithItems) => {
      const emissionSources = (template.emissionSources || []).map(
        (source) => ({
          emissionSourceId: source.id,
        }),
      )

      const templateForm: IEmissionTrackingTemplateForm = {
        ...template,
        emissionSources,
      }

      const newTemplate = await crud.create(templateForm)

      // TODO: remove after Sockets added
      await Promise.all([templateSourceStore.fetch()])

      return newTemplate
    }

    const patch = async (
      template: WithId<IEmissionTrackingTemplateWithItems>,
    ) => {
      const getTemplatesId = (id: number) =>
        templateSourceStore.emissionTrackingTemplateSources.find(
          ({ emissionSourceId }) => emissionSourceId === id,
        )?.trackingTemplateId

      const updatedTemplateSources: ITrackingTemplateSourceData[] = (
        template.emissionSources || []
      ).map((source) => ({
        trackingTemplateId: getTemplatesId(source.id),
        emissionSourceId: source.id,
      }))

      const templateForm: WithId<IEmissionTrackingTemplateForm> = {
        ...template,
        emissionSources: updatedTemplateSources,
      }

      await crud.patch(templateForm)
      // TODO: remove after Sockets added
      await Promise.all([templateSourceStore.fetch()])
    }

    const remove = async (
      template: WithId<IEmissionTrackingTemplateWithItems>,
    ) => {
      await crud.remove({ id: template.id })
    }

    return {
      templatesWithItems,
      templateOptions,
      emissionSourcesGroupedByTemplateId,
      create,
      patch,
      remove,
      fetchAll,
    }
  },
)
