import { defineStore, storeToRefs } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { esgProjectService } from '../../apiClient/services'
import { computed } from 'vue'
import { useEsgProjectRequirementGroupStore } from '../esgProjectRequirementGroup/index'
import { useOrgStore } from '../org'
import { useI18n } from 'vue-i18n'
import { createObjectsMap } from '@/utils'
import { IEsgProject } from '@/apiClient/types/esg'

export const useEsgProjectStore = defineStore('esgProject', () => {
  const socketService = useSocketsCrudFactory(esgProjectService, 'esgProject')

  const orgStore = useOrgStore()

  const { t } = useI18n()
  const { esgProjectRequirementGroupsByProjectIdMap } = storeToRefs(
    useEsgProjectRequirementGroupStore(),
  )

  /**
   * Map of ESG projects, indexed by ID
   */
  const projectByIdMap = computed(() =>
    createObjectsMap(esgProjects.value, 'id'),
  )

  const esgProjects = computed<IEsgProject[]>(() =>
    socketService.list.value.map((project) => {
      return {
        ...project,
        _projectLead: orgStore.orgUsers.find(
          ({ id }) => id === project.projectLeadId,
        ),
        _statusLabel: t(`esg.project.status.${project.status}`),
        _projectRequirementGroups:
          esgProjectRequirementGroupsByProjectIdMap.value[project.id] || [],
      }
    }),
  )

  return {
    ...socketService,
    esgProjects,
    projectByIdMap,
  }
})
