import { useI18n } from 'vue-i18n'
import { createObjectsArrayMap } from './../../utils/helpers'
import { createObjectsMap } from '@/utils'
import { emissionSourceService } from '@/apiClient/services'
import { IEmissionSource } from '@/apiClient/types/emissions'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useEmissionSourceStore = defineStore('emissionSource', () => {
  const crud = useSocketsCrudFactory(emissionSourceService, 'emissionSource')
  const { t } = useI18n()

  const emissionSources = computed(() => crud.list.value)

  const emissionSourcesTranlsated = computed<IEmissionSource[]>(() =>
    emissionSources.value.map((item) => ({
      ...item,
      name: t(item.name || ''),
    })),
  )

  const emissionSourcesMap = computed(() =>
    createObjectsMap(emissionSourcesTranlsated.value, 'id'),
  )

  const groupEmissionSourcesByCategory = (
    emissionSources: WithId<IEmissionSource>[],
  ) =>
    emissionSources.reduce((accum: Record<number, number[]>, source) => {
      if (!source.emissionCategoryId) return accum
      return {
        ...accum,
        [source.emissionCategoryId]: [
          ...(accum[source.emissionCategoryId] || []),
          source.id,
        ],
      }
    }, {})

  const emissionSourcesByCategoryMap = computed(() =>
    createObjectsArrayMap(
      emissionSourcesTranlsated.value,
      'emissionCategoryId',
    ),
  )

  return {
    emissionSources: emissionSourcesTranlsated,
    emissionSourcesByCategoryMap,
    emissionSourcesMap,
    groupEmissionSourcesByCategory,
    ...crud,
  }
})
