<template>
  <div
    class="TheTopbarOrgImpersonation"
    v-html="t('impersonatingOrg', { orgName: org?.name })"
  />
</template>

<script lang="ts" setup>
import { useOrgStore } from '@/store/org'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { org } = storeToRefs(useOrgStore())
</script>

<style scoped lang="scss">
.TheTopbarOrgImpersonation {
  width: 90%;
  background-color: $newcolor-danger-main;
  color: $color-white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
}
</style>
