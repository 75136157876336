import { EMISSION_TYPES } from './../../apiClient/types/emissions'
import { useI18n } from 'vue-i18n'
import { emissionCategoryService } from '@/apiClient/services'
import { IEmissionCategory } from '@/apiClient/types/emissions'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { createObjectsMap, groupByKey } from '@/utils'
import { useFeatureFlagStore } from '../featureFlag'
import { FeatureFlag } from '@/apiClient/types/featureFlag'
import { IAutocompleteItem } from '@/views/components/ui/inputs/UIInputAutocomplete/types'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useEmissionCategoryStore = defineStore('emissionCategory', () => {
  const crud = useSocketsCrudFactory(
    emissionCategoryService,
    'emissionCategory',
  )

  const { t } = useI18n()
  const emissionCategoriesTranslated = computed(() =>
    crud.list.value.map((item) => ({
      ...item,
      name: t(item.name),
      scopeSubcategory: t(item.scopeSubcategory),
      subcategory: t(item.subcategory),
    })),
  )

  const emissionCategoriesMap = computed<Record<number, IEmissionCategory>>(
    () =>
      createObjectsMap(
        crud.list.value.map((item) => ({
          ...item,
          name: t(item.name),
        })),
        'id',
      ),
  )

  const { hasFeature } = useFeatureFlagStore()

  /**
   * Options for UIInputSelect
   */
  const emissionCategoryOptions = computed(() =>
    emissionCategoriesTranslated.value
      .filter(({ isSilent, scope }) => {
        return (
          !isSilent &&
          (([1, 2].includes(scope) && hasFeature(FeatureFlag.scopeOneAndTwo)) ||
            ([3].includes(scope) && hasFeature(FeatureFlag.scopeThree)))
        )
      })
      .map((category) => {
        const primaryName = category.name
        const secondaryName = category.subcategory || category.scopeSubcategory
        return {
          title:
            primaryName === secondaryName
              ? primaryName
              : `${primaryName} (${secondaryName})`,
          value: category.id,
        }
      }),
  )

  /**
   * Sotred building emission categories.
   */
  const categoryEnergy = computed(() =>
    emissionCategoriesTranslated.value
      .filter(({ emissionType }) =>
        [EMISSION_TYPES.ENERGY].includes(emissionType),
      )
      .sort((a, b) => (a.id < b.id ? 1 : -1))
      .sort((a, b) => (a.scope < b.scope ? 1 : -1)),
  )

  /**
   * Sotred building emission categories.
   */
  const categoryBuildings = computed(() =>
    emissionCategoriesTranslated.value
      .filter(({ emissionType }) =>
        [
          EMISSION_TYPES.ENERGY,
          EMISSION_TYPES.FUEL,
          EMISSION_TYPES.HEATING,
        ].includes(emissionType),
      )
      .sort((a, b) => (a.id < b.id ? 1 : -1))
      .sort((a, b) => (a.scope < b.scope ? 1 : -1)),
  )

  /**
   * Stored refrigerant emission categories.
   */
  const categoryRefrigerants = computed(() =>
    emissionCategoriesTranslated.value
      .filter(({ emissionType }) =>
        [EMISSION_TYPES.REFRIGERANT].includes(emissionType),
      )
      .sort((a, b) => (a.id < b.id ? 1 : -1))
      .sort((a, b) => (a.scope < b.scope ? 1 : -1)),
  )

  /**
   * Stored transport emission categories.
   */
  const categoryTransport = computed(() =>
    emissionCategoriesTranslated.value
      .filter(({ emissionType }) =>
        [EMISSION_TYPES.TRANSPORT].includes(emissionType),
      )
      .sort((a, b) => (a.id < b.id ? 1 : -1))
      .sort((a, b) => (a.scope < b.scope ? 1 : -1)),
  )

  /**
   * Get category name based on emission category data
   */
  const getCategoryName = (item: IEmissionCategory) => {
    if (item.subcategory) {
      return `${item.subcategory} (${item.scopeSubcategory})`
    }

    return item.scopeSubcategory
  }

  const emissionCategoriesByScopeOptions = computed(() =>
    Object.entries(
      groupByKey(
        'scope',
        crud.list.value.filter(({ isSilent }) => !isSilent),
      ),
    ).reduce<IAutocompleteItem[]>((accum, [scope, children]) => {
      return [
        ...accum,
        {
          title: `Scope ${scope}`,
          value: `scope${scope}`,
          children: children.map((category) => ({
            title: getCategoryName(category),
            value: category.id,
          })),
        },
      ]
    }, []),
  )

  return {
    getCategoryName,
    emissionCategories: emissionCategoriesTranslated,
    emissionCategoriesByScopeOptions,
    emissionCategoriesMap,
    categoryRefrigerants,
    categoryTransport,
    emissionCategoryOptions,
    categoryBuildings,
    categoryEnergy,
    ...crud,
  }
})
