import { defineStore } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { taskParticipantService } from '../../apiClient/services'
import { computed } from 'vue'
import { useOrgStore } from '../org'
import { createObjectsArrayMap } from '@/utils'

export const useTaskParticipantStore = defineStore('taskParticipant', () => {
  const socketService = useSocketsCrudFactory(
    taskParticipantService,
    'taskParticipant',
  )

  const orgStore = useOrgStore()

  /**
   * Map of task participants, indexed by task ID
   */
  const taskParticipantsByTaskId = computed(() =>
    createObjectsArrayMap(taskParticipants.value, 'taskId'),
  )

  const taskParticipants = computed(() =>
    socketService.list.value.map((participant) => ({
      ...participant,
      _user: orgStore.orgUsers.find(({ id }) => id === participant.userId),
    })),
  )

  return {
    ...socketService,
    taskParticipants,
    taskParticipantsByTaskId,
  }
})
