import { IUITableColumn } from '@/views/components/ui/tables/types'
import { ITask } from './task'
import { IUser } from './user'

export const NUMBER_UNITS = {
  CURRENCY: 'CURRENCY',
  ENERGY: 'ENERGY',
  WEIGHT: 'WEIGHT',
  UNITS: 'UNITS',
  TCO2E: 'TCO2E',
  AREA: 'AREA',
  PERCENTAGE: 'PERCENTAGE',
  VOLUME: 'VOLUME',
}

export enum ESG_FRAMEWORK {
  GRI = 'GRI',
  ESRS = 'ESRS',
}

export enum ANSWER_TYPE {
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  ITERABLE_GROUP = 'ITERABLE_GROUP',
  CONFIG_WITH_CHILDREN = 'CONFIG_WITH_CHILDREN',
  MULTISELECT = 'MULTISELECT',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  SELECT_YEAR = 'SELECT_YEAR',
  STRING = 'STRING',
  TEXT = 'TEXT',
}

export enum ANSWER_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  DRAFT = 'DRAF',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED',
}

export enum ANSWER_OPTIONS_DYNAMIC_ENTITY {
  INTENSITY_METRIC = 'INTENSITY_METRIC',
  COMPANY = 'COMPANY',
  EMISSION_CATEGORY = 'EMISSION_CATEGORY',
  EMISSION_CATEGORY_SCOPE_1 = 'EMISSION_CATEGORY_SCOPE_1',
  EMISSION_CATEGORY_SCOPE_2 = 'EMISSION_CATEGORY_SCOPE_2',
  EMISSION_CATEGORY_SCOPE_3 = 'EMISSION_CATEGORY_SCOPE_3',
}

export enum PROJECT_STATUS {
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum ESG_REQUIREMENT_GROUP_TYPE {
  TOPIC = 'TOPIC',
  DISCLOSURE = 'DISCLOSURE',
}

export enum ESG_TAG_TYPE {
  EQUIVALENT = 'EQUIVALENT',
  RELATES_TO = 'RELATES_TO',
  DEPENDS_ON = 'DEPENDS_ON',
  PARAGRAPH = 'PARAGRAPH',
}

//#region ESG Framework Standard
export interface IEsgFrameworkStandard extends IEsgFrameworkStandardData {
  id: number
  // _requirementGroups: IEsgRequirementGroup[]
}
export interface IEsgFrameworkStandardData {
  esgFramework: ESG_FRAMEWORK
  name: string
  description: string
  step: number
  isDisabled: boolean
}
//#endregion

//#region ESG RequirementGroup
export interface IEsgRequirementGroup extends IEsgRequirementGroupData {
  id: number
  _subGroups: IEsgRequirementGroup[]
  _esgTopic?: IEsgRequirementGroup | undefined
  _esgFrameworkStandard?: IEsgFrameworkStandard
  _requirements: IEsgRequirement[]
  _progress?: {
    countTotal: number
    countCompleted: number
  }
}

export interface IEsgRequirementGroupData {
  frameworkStandardId: number
  parentGroupId?: number
  type: ESG_REQUIREMENT_GROUP_TYPE
  resourceId: string
  name: string
  description?: string
  note?: string
  recommendation?: string
  guidance?: string
  version: string
  isOptional?: boolean
}
//#endregion

//#region ESG Requirement
export interface IEsgRequirement extends IEsgRequirementData {
  id: number
  _subRequirements?: IEsgRequirement[]
  _dependsOn?: IEsgRequirement[]
  _paragraphs?: IEsgParagraph[]
  _progress?: {
    countTotal: number
    countCompleted: number
    countSkipped: number
  }
}
export interface IEsgProjectRequirement extends IEsgRequirement {
  esgProjectId: number
  _subRequirements?: IEsgProjectRequirement[]
  _projectAnswers?: IEsgProjectAnswer[]
  _progress: {
    countTotal: number
    countCompleted: number
    countSkipped: number
  }
}

export interface IEsgRequirementData {
  resourceId: string
  esgRequirementGroupId: number
  parentRequirementId?: number
  name: string
  description?: string
  answerConfig?: IEsgRequirementAnswerConfig[]
}

// export interface IEsgRequirementRelationships {
//   subRequirements: IEsgRequirement[]
// }

interface Compute {
  row: string
  columnKey: string
  variables: string[]
  value: string
}

interface GHGPrefillQuery {
  year: string
  emissionSourceKey: string
}

interface GHGPrefill {
  row: string
  columnKey: string
  query: GHGPrefillQuery | GHGPrefillQuery[]
}

export interface IEsgRequirementAnswerConfig {
  id: string
  label: string
  isDisabled: boolean | string
  isOptional: boolean
  isHidden: boolean | string
  tooltip: string
  units: string[] | boolean
  type: ANSWER_TYPE
  items: string[]
  dynamicResourceItem: string
  isMultiValue: boolean
  isAllRequired?: boolean
  compute?: string | Compute | Compute[]
  children?: IEsgRequirementAnswerConfig[]
  isSelectItemsRequired?: boolean
  paragraphs: IEsgParagraph[]
  // Table props
  columns: IUITableColumn[]
  rows: IEsgRequirementAnswerConfig[]
  /**
   * Min width for table columns
   */
  minWidth?: string
  /**
   * Max width for table columns
   */
  maxWidth?: string
  ghgPrefill: GHGPrefill
}
//#endregion

//#region ESG Requirement
export interface IEsgTag extends IEsgTagData {
  id: number
}
export interface IEsgTagData {
  type: ESG_TAG_TYPE
  sourceId: number
  targetId: number
}
//#endregion

//#region ESG Project
export interface IEsgProject extends IEsgProjectData {
  id: number
  _projectRequirementGroups: IEsgProjectRequirementGroup[]
  _requirements?: IEsgProjectRequirement[]
  _projectLead?: IUser
  _statusLabel?: string
}
export interface IEsgProjectData {
  orgId: number
  year: number
  esgFramework: ESG_FRAMEWORK
  status: PROJECT_STATUS
  projectLeadId: number
  dueDate: string
}
//#endregion

//#region ESG Project
export interface IEsgProjectRequirementGroup
  extends IEsgProjectRequirementGroupData {
  id: number
  _requirementGroup: IEsgRequirementGroup
  _task?: ITask
  _requirements: IEsgProjectRequirement[]
  _progress?: {
    countTotal: number
    countCompleted: number
    countSkipped?: number
  }
}
export interface IEsgProjectRequirementGroupData {
  esgProjectId: number
  esgRequirementGroupId: number
}
//#endregion

//#region ESG Project Answer
export interface IEsgProjectAnswer extends IEsgProjectAnswerData {
  id: number
}

export interface IEsgProjectAnswerData {
  orgId: number
  esgProjectId: number
  esgRequirementId: number
  userId?: number
  editedByUserId?: number
  answerConfigId: string
  status: ANSWER_STATUS
  isSkipped?: boolean
  value: {
    key?: string
    value?: any
    [key: string]: any
  }[]
}
//#endregion

//#region ESG Report
export interface IEsgReport {
  resourceId: string
  name: string
  data: IEsgReportDataRow[]
  raw: any
}
export interface IEsgReportDataRow {
  name: string
  resourceId: string
  value: string | number | boolean
}
// #endregion

export type IEsgTrackedIndicator = {
  id: number
}

export interface IEsgParagraph extends IEsgParagraphData {
  id: number
}

export interface IEsgParagraphData {
  resourceId: string
  esgFramework: ESG_FRAMEWORK
  esgStandard: string
  url: string
}

//#region DEPRECATING
// export interface IEsgProjectRequirement
//   extends IEsgProjectRequirementData,
//     ISchemaRelationship<IEsgProjectRequirementRelationships> {
//   id: number
// }
export interface IEsgProjectRequirementData {
  orgId: number
  esgProjectId: number
  esgTopicId: number
  esgDisclosureId: number
  esgProjectDisclosureId: number
  esgRequirementId: number
}
export interface IEsgProjectRequirementRelationships {
  esgRequirement: IEsgRequirement
  answers: IEsgProjectAnswer[]
  subProjectRequirements: IEsgProjectRequirement[]
}
export interface IEsgProjectDisclosureComputed {
  progress: {
    countTotal: number
    countCompleted: number
  }
}
export interface IEsgProjectDisclosure
  extends IEsgProjectDisclosureData,
    ISchemaComputed<IEsgProjectDisclosureComputed>,
    ISchemaRelationship<IEsgProjectDisclosureRelationships> {
  id: number
}
export interface IEsgProjectDisclosureData {
  orgId: number
  esgProjectId: number
  esgTopicId: number
  esgDisclosureId: number
}
export interface IEsgProjectDisclosureRelationships {
  esgDisclosure: IEsgDisclosure
  tasks: ITask[]
  topic: IEsgTopic
}

export interface IEsgTopic extends IEsgTopicData {
  id: number
}
export interface IEsgTopicData {
  resourceId: string
  name: string
  description?: string
  esgFramework: ESG_FRAMEWORK
}
export interface IEsgDisclosure extends IEsgDisclosureData {
  id: number
}
export interface IEsgDisclosureData {
  resourceId: string
  name: string
  description?: string
  note?: string
  recommendation?: string
  guidance?: string
  esgTopicId: number
}
//#endregion
