import { RouteMeta, RouteRecordRaw } from 'vue-router'

export const addRoutePrefix =
  (prefix: string, routeMeta: RouteMeta = {}) =>
  (route: RouteRecordRaw): RouteRecordRaw => ({
    ...route,
    path: `/${prefix}/${route.path}`.replace('//', '/'),
    children: route.children?.map(addRoutePrefix(prefix)) || [],
    meta: {
      ...(route.meta || {}),
      ...routeMeta,
    },
  })
