import { IOrganisation } from '@/apiClient/types/organisation'
import { defineStore, storeToRefs } from 'pinia'
import { ref, reactive, toRefs, watch } from 'vue'
import { useUserStore } from '@/store/user'
import { orgService, userService } from '@/apiClient/services'
import { computed } from 'vue'
import { getYearsRange } from '@/utils'
import { IUser } from '@/apiClient/types/user'

export const useOrgStore = defineStore('organisation', () => {
  const org = ref<IOrganisation>()
  const { user } = storeToRefs(useUserStore())
  const state = reactive({
    isFetching: ref(false),
    isPatching: ref(false),
    isCreating: ref(false),
  })

  const orgYearsRange = computed(() =>
    getYearsRange(org.value?.baseYear || 2020),
  )

  const fetchOrg = async (force = true) => {
    const orgId = user.value?.orgId

    if (!orgId) {
      throw new Error('Cannot fetch org! Missing org ID')
    }

    if (org.value && !force) return

    state.isFetching = true
    org.value = await orgService.get(orgId).finally(() => {
      state.isFetching = false
    })
  }

  const createOrg = async (params: Partial<IOrganisation>) => {
    state.isCreating = true

    return await orgService
      .create(params)
      .then((result) => (org.value = result))
      .finally(() => (state.isCreating = false))
  }

  const patchOrg = async () => {
    if (!org.value) {
      throw new Error('Could not patch the organisation! Org missing')
    }

    state.isPatching = true

    org.value = await orgService
      .patch(org.value.id, org.value)
      .finally(() => (state.isPatching = false))
  }

  const orgUsers = ref<IUser[]>([])
  const fetchOrgUsers = () => {
    if (!org.value) return
    userService.find({ query: { orgId: org.value.id } }).then((res) => {
      orgUsers.value = res.data
    })
  }

  watch(
    org,
    (newOrg) => {
      if (newOrg?.id) {
        fetchOrgUsers()
      }
    },
    { immediate: true },
  )

  return {
    ...toRefs(state),
    org,
    orgYearsRange,
    fetchOrg,
    patchOrg,
    createOrg,
    orgUsers,
  }
})
