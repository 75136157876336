<template>
  <div class="UIChatArea">
    <UIChatItem
      v-for="(item, index) in chatItems"
      :key="index"
      :chatItem="item"
    />
    <UIChatTypingIndicator v-if="isLoading" />
    <div
      v-if="showPlaceholder && !chatItems.length && !isLoading"
      class="UIChatArea__placeholder"
    >
      <img src="/logo-icon.svg" />
      {{ t('chatPlaceholder') }}
    </div>
    <div ref="bottomElement" />
  </div>
</template>

<script lang="ts" setup>
import { PropType, nextTick, ref, watch } from 'vue'
import UIChatItem from './UIChatItem.vue'
import UIChatTypingIndicator from './UIChatTypingIndicator.vue'
import { useI18n } from 'vue-i18n'

export interface IChatItem {
  chatLineType: 'message' | 'response'
  content: string
}

const props = defineProps({
  chatItems: {
    type: Array as PropType<IChatItem[]>,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  showPlaceholder: {
    type: Boolean,
    default: true,
  },
})

const { t } = useI18n()

const bottomElement = ref<HTMLElement>()
const scrollToTheBottomElement = async (options?: object) => {
  await nextTick()
  bottomElement.value?.scrollIntoView({ block: 'start', ...options })
}

watch(
  () => props.chatItems.length,
  async () => {
    scrollToTheBottomElement()
  },
  { immediate: true },
)

defineExpose({
  scrollToTheBottomElement,
})
</script>

<style lang="scss" scoped>
.UIChatArea {
  height: 100%;
  height: 100%;
  overflow-y: auto;
  @include customScrollbar;
  padding: 0.5rem 0.5rem;

  .UIChatArea__placeholder {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: $newcolor-grey-400;
    flex-direction: column;

    img {
      max-width: 2.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
