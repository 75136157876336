/**
 * Creates a map for { ID: prop }. Note
 * @param arr Array of objects to map
 * @param prop property to be mapped to the ID
 */

import { IRecordData } from '@/apiClient/types/recordData'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

/**
 * Creates a new object where the keys are the values of the specified key in the input objects,
 * and the values are the input objects themselves.
 *
 * @template T - The type of the input objects.
 * @template K - The type of the key used to create the map.
 * @param {T[]} arr - The input array of objects.
 * @param {K} key - The key used to create the map.
 * @returns {Record<T[K], T>} - The new object with keys as specified property and values as objects.
 */
export function createObjectsMap<T extends object, K extends keyof T>(
  arr: T[],
  key: K,
): Record<T[K], T> {
  return arr.reduce(
    (accum, item) => ({
      ...accum,
      [item[key]]: item,
    }),
    {} as Record<T[K], T>,
  )
}
/**
 * Creates a new object where the keys are the values of the specified key in the input objects,
 * and the values are the input object array.
 *
 * @template T - The type of the input objects.
 * @template K - The type of the key used to create the map.
 * @param {T[]} arr - The input array of objects.
 * @param {K} key - The key used to create the map.
 * @returns {Record<T[K], T>} - The new object with keys as specified property and values as objects.
 */
export function createObjectsArrayMap<T extends object, K extends keyof T>(
  arr: T[],
  key: K,
): Record<T[K], T[]> {
  return arr.reduce((accum, item) => {
    return {
      ...accum,
      [item[key]]: [...(accum[item[key]] || []), item],
    }
  }, {} as Record<T[K], T[]>)
}
/**
 * Creates a new object where the keys are the values of the specified key in the input objects,
 * and the values are the values of the specified property in the input objects.
 *
 * @template T - The type of the input objects.
 * @template K - The type of the key used to create the map.
 * @param {T[]} arr - The input array of objects.
 * @param {K} key - The key used to create the map.
 * @param {keyof T} mapProperty - The property to be mapped to the key values.
 * @returns {Record<T[K], T[keyof T]>} - The new object with keys as specified property and values as specified property.
 */
export function createPropertyMap<
  T extends object,
  K extends keyof T,
  L extends keyof T,
>(arr: T[], key: K, mapProperty: L): Record<T[K], T[L]> {
  return arr.reduce(
    (accum, item) => ({
      ...accum,
      [item[key]]: item[mapProperty],
    }),
    {} as Record<T[K], T[L]>,
  )
}

/**
 * Groups array of objects by object key
 * @param key group key
 * @param arr Array of objects to be grouped
 */
export const groupByKey = <T extends Record<string, unknown>>(
  key: string | number,
  arr: T[],
) => {
  return arr.reduce(
    (accum, item) => ({
      ...accum,
      [item[key] as any]: [...(accum[item[key] as any] || []), item],
    }),
    {} as { [id: number | string]: T[] },
  )
}

/**
 * Rounds the emission source to 12 decimal points
 * @param factor emission source
 */
export const roundEmissionFactor = (factor: number, precision = 12) => {
  const multiplier = Math.pow(10, precision)
  const roundedValue = Math.round(factor * multiplier) / multiplier
  const roundedString = roundedValue.toFixed(precision)
  const finalValue = Number(roundedString)

  return parseFloat(finalValue.toFixed(precision))
}

export function unique<T>(array: T[]) {
  return [...new Set(array)]
}

export function getYearsRange(startYear: number, endYear?: number) {
  const end = endYear || new Date().getFullYear()
  const years = []
  for (let i = startYear; i <= end; i++) {
    years.push(i)
  }

  return years
}

export function getRange(start: number, end: number) {
  const range = []
  for (let i = start; i <= end; i++) {
    range.push(i)
  }

  return range
}

export const transformToOption = (item: any) => ({
  title: item.name,
  value: item.id,
  raw: item,
})

/**
 * Sorts recordData items
 * @param recordDataItems Array of recordData items
 */
export const sortByYearMonth = (recordDataItems: IRecordData[]) =>
  recordDataItems.sort((a, b) =>
    parseInt(a.yearMonth?.split('-')[1] || '0') >
    parseInt(b.yearMonth?.split('-')[1] || '0')
      ? 1
      : -1,
  )

export const prefillVersionedEntries = <
  T extends Record<
    string,
    { id?: number; version: string; inheritedFrom?: string }
  >,
>(
  versionsRange: number[],
  versionedEntries: T,
  init?: unknown,
) =>
  versionsRange.reduce((accum: T, version) => {
    if (versionedEntries[version]) {
      return {
        ...accum,
        [version]: versionedEntries[version],
      }
    }

    const previousVersion = accum[String(version - 1)]

    if (previousVersion) {
      return {
        ...accum,
        [version]: {
          ...previousVersion,
          version: String(version),
          id: null,
          inheritedFrom:
            previousVersion.inheritedFrom || previousVersion.version,
        },
      }
    }

    const availableVersions = Object.keys(versionedEntries).sort()

    if (availableVersions.length) {
      return {
        ...accum,
        [version]: {
          ...versionedEntries[availableVersions[0]],
          version: String(version),
          id: null,
          inheritedFrom: versionedEntries[availableVersions[0]].version,
        },
      }
    }

    return {
      ...accum,
      [version]: {
        version: String(version),
        ...(init || {}),
      },
    }
  }, {} as T)

type NestedObject = {
  [key: string]: any
}

export const compareNestedObjects = <T extends NestedObject>(
  obj1: Partial<T>,
  obj2: Partial<T>,
): Partial<T> => {
  let result: NestedObject = {}

  for (const key in { ...obj1, ...obj2 }) {
    if (
      typeof obj1[key] === 'object' &&
      typeof obj2[key] === 'object' &&
      !Array.isArray(obj1[key]) &&
      !Array.isArray(obj2[key])
    ) {
      const nestedResult = compareNestedObjects(
        obj1[key] || {},
        obj2[key] || {},
      )
      if (Object.keys(nestedResult).length > 0) {
        result[key] = nestedResult
      }
    } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      const changedObjects = obj2[key].filter(
        (obj2Item: NestedObject, index: number) => {
          const obj1Item = obj1[key][index]
          if (obj1Item) {
            return JSON.stringify(obj1Item) !== JSON.stringify(obj2Item)
          }

          return false
        },
      )

      if (changedObjects.length > 0) {
        result[key] = changedObjects
      }
    } else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      result = { ...obj2 }
    }
  }

  return result as Partial<T>
}

export const clone = (value: any) => JSON.parse(JSON.stringify(value))

const maxDecimalPlaces = (numbers: number[]): number => {
  return numbers.reduce((maxPrecision: number, number: number) => {
    const decimalPlaces = (number.toString().split('.')[1] || '').length
    return Math.max(maxPrecision, decimalPlaces)
  }, 0)
}

const roundNumber = (num: number, precision: number): number => {
  const factor = Math.pow(10, precision)
  return Math.round(num * factor) / factor
}

export const sumFloats = (floatArray: number[]): number => {
  const sum = floatArray.reduce((accumulator: number, currentValue: number) => {
    return accumulator + currentValue
  }, 0)

  const precision = maxDecimalPlaces(floatArray)
  return roundNumber(sum, precision)
}

export const bulkCrud = <
  T extends { id: number; isRemoved?: boolean },
  K extends ReturnType<typeof useSocketsCrudFactory<T>>,
>(
  service: Pick<K, 'create' | 'patch' | 'remove'>,
  items: Partial<T>[],
  override: object = {},
) => {
  const promises = []

  const recordsCreated = items
    .filter(({ id, isRemoved }) => !id && !isRemoved)
    .map((record) => ({ ...record, ...override }))

  if (recordsCreated.length) {
    promises.push(...recordsCreated.map((record) => service.create(record)))
  }

  const recordsUpdated = items
    .filter(({ id, isRemoved }) => id && !isRemoved)
    .map((record) => ({ ...record, ...override }))

  if (recordsUpdated.length) {
    promises.push(...recordsUpdated.map((record) => service.patch(record)))
  }

  const recordsRemoved = items.filter(({ id, isRemoved }) => id && isRemoved)

  if (recordsRemoved.length) {
    promises.push(service.remove(recordsRemoved))
  }

  return Promise.all(promises)
}

export const toISODate = (date: Date) => date.toISOString().slice(0, 10)

/**
 * Deep clones an object
 * @param obj Object to be cloned
 */
export const deepClone = (target: object | object[]) =>
  JSON.parse(JSON.stringify(target))

/**
 * Checks if object a is a subset of object b
 * @param a Object to be checked
 * @param b Object to be checked against
 */
export const isObjectSubset = (
  a: Record<string, any>,
  b: Record<string, any>,
) => {
  return Object.entries(a).every(([key, value]) => {
    return b[key] === value
  })
}

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
export function deepMerge<T extends Record<string, any>>(
  target: T,
  source: T,
): T {
  const newTarget: Record<string, any> = { ...target }
  const isObject = (obj: any): obj is T => obj && typeof obj === 'object'

  if (!isObject(target) || !isObject(source)) {
    throw new Error('Both parameters should be objects')
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key]
    const sourceValue = source[key]

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      newTarget[key] = sourceValue.map((sourceItem) => {
        if (sourceItem.id !== undefined) {
          const targetItem = targetValue.find(
            (targetItem) => targetItem.id === sourceItem.id,
          )
          if (targetItem) {
            return deepMerge(targetItem, sourceItem)
          }
        }

        return sourceItem
      })
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      newTarget[key] = deepMerge({ ...targetValue }, sourceValue)
    } else {
      newTarget[key] = sourceValue
    }
  })

  return newTarget as T
}

export function deepMergeArrays<
  T extends { id?: number } = Record<string, any>,
>(target: T[], source: T[]): T[] {
  // Create a new array from target and source.
  // Make sure to subtract items with the same ID.
  const allItems = [...target, ...source]
  const merged: { [id: number]: T } = {}

  allItems.forEach((item) => {
    if (item.id !== undefined) {
      if (merged[item.id]) {
        merged[item.id] = deepMerge(merged[item.id], item)
      } else {
        merged[item.id] = item
      }
    } else {
      throw new Error('All items should have an id')
    }
  })

  return Object.values(merged)
}

/**
 * Groups an array of objects by multiple keys, nesting each level of grouping according to the order of keys.
 * This function is useful for categorizing a flat list of items into a hierarchical structure based on the item properties.
 *
 * @param {string[]} keys - An array of strings representing the keys to group the items by, in the order of grouping.
 * @param {Item[]} items - An array of objects to be grouped. Each item must at least have the properties specified in keys.
 * @returns {object} - The grouped items in a nested object structure, where each level of the object corresponds to a key by which the items are grouped.
 *
 * @example
 * // Group items by 'emissionSourceId', then by 'version', and finally by 'type'.
 * const groupedItems = groupItemsByNestedKeys(['emissionSourceId', 'version', 'type'], items);
 *
 * // Output structure:
 * // {
 * //   '1': {
 * //     '2022': {'A': [{ emissionSourceId: 1, version: '2022', type: 'A' }]},
 * //     '2023': {'A': [{ emissionSourceId: 1, version: '2023', type: 'A' }]}
 * //   },
 * //   '2': {
 * //     '2023': {'B': [{ emissionSourceId: 2, version: '2023', type: 'B' }]},
 * //     '2022': {'A': [{ emissionSourceId: 2, version: '2022', type: 'A' }]}
 * //   }
 * // }
 */

export function groupItemsByNestedKeys<T extends object, K extends keyof T>(
  levelItems: T[],
  remainingKeys: K[],
) {
  if (remainingKeys.length === 0) {
    // No more keys to group by; return the items as they are.
    return levelItems
  }

  const currentKey = remainingKeys[0]
  // TODO replace any
  const nextKeys: any = remainingKeys.slice(1)
  const grouped: { [key: string]: any } = {} // "any" is used here for nested structures

  levelItems.forEach((item) => {
    const keyValue = item[currentKey] as string
    if (grouped[keyValue] === undefined) {
      grouped[keyValue] = []
    }
    grouped[keyValue].push(item)
  })

  // If there are more keys, recurse into the grouped items to further nest them.
  if (nextKeys.length > 0) {
    Object.keys(grouped).forEach((key) => {
      grouped[key] = groupItemsByNestedKeys(grouped[key], nextKeys)
    })
  }

  return grouped
}
