import { defineStore } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { esgParagraphService } from '../../apiClient/services'
import { computed } from 'vue'
import { createObjectsMap } from '@/utils'

export const useEsgParagraphStore = defineStore('esgParagraph', () => {
  const socketService = useSocketsCrudFactory(
    esgParagraphService,
    'esgParagraph',
  )

  const esgParagraphMap = computed(() =>
    createObjectsMap(socketService.list.value, 'id'),
  )

  return {
    ...socketService,
    esgParagraphMap,
  }
})
