<template>
  <div :class="['UIToast', `UIToast__${position}`]">
    <TransitionGroup name="UIToast__item">
      <div
        v-for="toast in toastStore.toasts"
        :key="toast.id"
        :class="['UIToast__item', `UIToast__item-${toast.type}`]"
      >
        <div class="UIToast__item-content">
          <UIIcon class="UIToast__icon" :name="typeIconMap[toast.type]" />
          <div class="UIToast__item-text">
            <h5 class="UIToast__item-title">{{ toast.title }}</h5>
            <div v-html="toast.message" />
          </div>
          <UIButtonIcon
            icon="close"
            @click="() => toastStore.removeToast(toast.id)"
          />
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import UIIcon from '@/views/components/ui/icons/UIIcon.vue'
import UIButtonIcon from '@/views/components/ui/buttons/UIButtonIcon.vue'
import { useToast } from '@/store/toast'
import { PropType } from 'vue'
import { TOAST_POSITION, TOAST_TYPE, TOAST_ICONS } from './types'

const toastStore = useToast()

const typeIconMap = {
  [TOAST_TYPE.SUCCESS]: TOAST_ICONS.SUCCESS,
  [TOAST_TYPE.ERROR]: TOAST_ICONS.ERROR,
  [TOAST_TYPE.INFO]: TOAST_ICONS.INFO,
  [TOAST_TYPE.WARNING]: TOAST_ICONS.INFO,
}

defineProps({
  position: {
    type: String as PropType<TOAST_POSITION>,
    default: TOAST_POSITION.TOP_RIGHT,
  },
})
</script>

<style lang="scss" scoped>
.UIToast {
  z-index: 9999;
  position: fixed;
  padding: 1rem;
  max-width: 27rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  &__item {
    background: white;
    position: relative;
    pointer-events: auto;
    background: $color-white;
    box-shadow: 0 3px 12px 1px rgb(43 55 72 / 15%);
    border-radius: 0.25rem;
    opacity: 1;
    border-left: 0.25rem solid rgba(0, 0, 0, 0);
    padding: 1.25rem 1rem 1.25rem 1.25rem;
    width: 100%;
    max-width: 100%;
    font-size: 0.875rem;
    line-height: 1.3rem;
    margin-bottom: 1rem;

    &-content {
      display: flex;
      align-items: center;
      width: 100%;

      .UIIcon {
        --size: 1.5rem;
      }
      .UIButtonIcon {
        --icon-size: 1.25rem;
        margin-left: 0.25rem;
      }
    }

    &-title {
      margin-bottom: 0.35rem;
      font-size: 1rem;
    }

    &-text {
      width: 100%;
      margin-left: 0.75rem;

      :deep(a) {
        color: $newcolor-primary-main;
        text-decoration: none;
      }
    }

    @mixin toastTheme($color) {
      border-color: $color;
      .UIToast__icon {
        --color: #{$color};
      }
    }

    &-success {
      @include toastTheme($newcolor-success-main);
    }
    &-error {
      @include toastTheme($newcolor-danger-main);
    }
    &-info {
      @include toastTheme($newcolor-info-main);
    }
    &-warning {
      @include toastTheme($newcolor-warning-main);
    }

    // UIToast item animation
    &-move,
    &-enter-active,
    &-leave-active {
      transition: all 0.5s ease;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateX(1.5rem);
    }
    &-leave-active {
      position: absolute;
    }
  }

  &__top-right {
    top: 0rem;
    right: 0rem;
  }

  &__top-left {
    top: 0rem;
    left: 0rem;
  }

  &__bottom-left {
    bottom: 0rem;
    left: 0rem;
  }

  &__bottom-right {
    bottom: 0rem;
    left: 0rem;
  }
}
</style>
