<template>
  <div class="UIIcon-wrapper">
    <Component :is="iconComponent" class="UIIcon" :title="name" :key="name" />
  </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, PropType } from 'vue'
import { toPascalCase } from '@/utils'
import { IIcons } from './types'

const props = defineProps({
  name: { type: String as PropType<IIcons>, required: true },
})

const iconName = computed(() => toPascalCase(`icon ${props.name}`))
const iconComponent = defineAsyncComponent(
  () => import(`./components/${iconName.value}.vue`),
)
</script>

<style scoped lang="scss">
.UIIcon-wrapper {
  --size: 16px;
  min-height: var(--size);
  min-width: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
}
.UIIcon {
  height: var(--size);
  width: var(--size);
  min-height: var(--size);
  min-width: var(--size);
  display: flex;
  fill: var(--color, #{$newcolor-secondary-main});
}
</style>
