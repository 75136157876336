<template>
  <div
    :class="[
      'UIInputText',
      {
        'UIInputText--no-margin': noMargin,
        'UIInputText--has-errors': hasErrors,
        'UIInputText--is-disabled': isDisabled,
      },
      props.class,
    ]"
    :data-testid="`UIInputText[${testId || name}]`"
  >
    <UIInputLabel
      v-if="label || $slots['label']"
      class="UIInputText__label"
      :for="name"
      :isRequired="isRequired"
    >
      <slot name="label">{{ label }}</slot>

      <slot name="tooltip">
        <UITooltip v-if="$slots['tooltip-content']">
          <template #content>
            <slot name="tooltip-content" />
          </template>
        </UITooltip>
      </slot>
    </UIInputLabel>

    <div class="UIInputText__input-wrapper">
      <div
        v-if="$slots['icon-before']"
        class="UIInputText__input-icon UIInputText__input-icon--before"
      >
        <slot name="icon-before" />
      </div>
      <input
        :data-np-disabled="disableNordPassAutocomplete"
        v-bind="$attrs"
        :id="name"
        :class="[
          'UIInputText__input',
          {
            'UIInputText__input--icon-before': $slots['icon-before'],
            'UIInputText__input--icon-after': $slots['icon-after'],
          },
        ]"
        :value="valueFormatted"
        @input="inputValue"
        :data-cy="`input[${name}]`"
        :data-testid="`input[${testId || name}]`"
        :disabled="isDisabled"
        :step="step"
        ref="input"
        :type="type"
      />
      <div
        v-if="$slots['icon-after']"
        class="UIInputText__input-icon UIInputText__input-icon--after"
      >
        <slot name="icon-after" />
      </div>

      <div v-if="$slots['append-wrapper']" class="UIInputText__append-wrapper">
        <slot name="append-wrapper" />
      </div>
    </div>
    <template v-for="error in displayErrors" :key="error">
      <div class="UIInputText__error">
        {{ error }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useFormatters } from '@/composables/useFormatters'
import { useField } from 'vee-validate'
import { PropType, computed, onMounted, ref, toRef, useAttrs } from 'vue'
import UITooltip from '../tooltips/UITooltip.vue'
import { BaseSchema } from 'yup'
import UIInputLabel from './UIInputLabel.vue'

defineOptions({
  inheritAttrs: false,
})
const props = defineProps({
  class: { type: String, default: '' },
  label: { type: String, required: false },
  name: { type: String, required: true },
  testId: { type: String, default: '' },
  modelValue: { type: [String, Number], required: false },
  isDisabled: { type: Boolean, default: false },
  isRequired: { type: Boolean, default: false },
  /**
   * If true then margin-bottom is set to 0
   */
  noMargin: { type: Boolean, default: true },
  isFloat: { type: Boolean },
  autofocus: { type: Boolean, required: false },
  /**
   * yup rule validator for vee-validate
   **/
  validator: {
    type: Object as PropType<BaseSchema>,
    default: () => ({}),
  },
  /**
   * If true then numbers will be formatted
   * @example 10000 will be formatted as 10,000
   */
  shouldFormatNumbers: {
    type: Boolean,
    default: true,
  },
  customFormating: {
    type: Function,
  },
  disableNordPassAutocomplete: {
    type: Boolean,
    default: true,
  },
  customErrors: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  isNumberType: {
    type: Boolean,
    default: false,
  },
})

const hasErrors = computed(
  () => errors.value.length || props.customErrors.length,
)

const { value, errors } = useField(
  toRef(props, 'name'),
  toRef(props, 'validator'),
  {
    initialValue: props.modelValue,
    syncVModel: true,
  },
)

const displayErrors = computed(() => [...errors.value, ...props.customErrors])
const valueFormatted = computed(() => {
  if (value.value === undefined) {
    return undefined
  } else if (
    attrs.type === 'number' &&
    props.customFormating &&
    props.shouldFormatNumbers
  ) {
    return formatNumber(
      props.customFormating(Number(value.value)),
      props.isFloat ? 11 : 2,
    )
  } else if (attrs.type === 'number' && props.shouldFormatNumbers) {
    return formatNumber(Number(value.value), props.isFloat ? 11 : 2)
  } else {
    return value.value
  }
})
const step = computed(() => (props.isFloat ? Math.pow(10, -12) : undefined))
const input = ref()

const attrs = useAttrs()
const type = computed<string>(() =>
  attrs.type === 'number' && props.shouldFormatNumbers
    ? 'text'
    : (attrs.type as string),
)

const { formatNumber, parseNumber } = useFormatters()

const emits = defineEmits(['update:modelValue'])

const inputValue = (payload: Event) => {
  const target = payload.target as HTMLInputElement

  if (!target) return

  if (attrs.type !== 'number') {
    value.value = target.value
    return emits('update:modelValue', target.value)
  }

  const numberParsed = parseNumber(target.value)
  const notFinishedNumberWithDot = /\d+[,.](\d*0+|)$/

  if (
    target.value === null ||
    target.value === undefined ||
    target.value === ''
  ) {
    input.value.value = undefined
    value.value = undefined
  }
  // This ensures that only numeric inputs are formated
  else if (!notFinishedNumberWithDot.test(target.value)) {
    input.value.value = formatNumber(numberParsed, props.isFloat ? 11 : 2)

    value.value = numberParsed
  } else {
    input.value.value = target.value
    value.value = numberParsed
  }

  emits('update:modelValue', value.value)
}

onMounted(() => {
  if (props.autofocus && input.value) {
    input.value.focus()
    input.value.select()
  }
})
</script>

<style scoped lang="scss">
.UIInputText {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 1.25rem;

  --inner-slot-width: 1rem;
  --active-color: #{$newcolor-primary-main};
  --label-color: #{$newcolor-grey-700};
  --focus-shadow: 0 0 0 3px rgba(15, 172, 129, 0.1);


  &.UIInputText--no-margin {
    margin-bottom: 0;
  }

  &.UIInputText--has-errors {
    --active-color: #{$newcolor-danger-main};
    --label-color: #{$newcolor-danger-main};
    --focus-shadow: 0 0 0 3px rgba(232, 83, 71, 0.1);

    .UIInputText__input {
      border-color: var(--active-color);
    }
  }

  &.UIInputText--is-disabled {
    .UIInputText__input {
      pointer-events: none;
      background-color: $newcolor-grey-100;
    }
  }

  .UIInputText__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .UIInputText__input-icon {
    position: absolute;
    height: calc(100% - 2px);
    background: none;
    top: 1px;
    width: calc(var(--inner-slot-width) + 1rem - 2px);
    display: flex;
    align-items: center;
    justify-content: center;

    &--before {
      left: 1px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &--after {
      right: 1px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .UIInputText__input {
    display: block;
    width: 100%;
    padding: 0.6875rem 1rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: $newcolor-grey-700;
    background-color: $color-white;
    border: 1px solid $newcolor-border;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;

    &--icon-before {
      padding-left: calc(var(--inner-slot-width) + 1rem);
    }
    &--icon-after {
      padding-right: calc(var(--inner-slot-width) + 1rem);
    }

    &:focus {
      border-color: var(--active-color);
      outline: 0;
      box-shadow: var(--focus-shadow);
    }

    &::placeholder {
      color: $newcolor-grey-400;
    }
  }

  .UIInputText__error {
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: $newcolor-danger-main;
    position: absolute;
    bottom: -1.25rem;
  }

  // input[type='date']::-webkit-calendar-picker-indicator {
  //   // opacity: 0;
  //   float: right;
  //   width: 100%;
  //   -webkit-appearance: none;
  // }
}
</style>
