import { useCountryStore } from './../country/index'
import { defineStore, storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useOrgStore } from '../org/index'
import { useCompanyStore } from '../company/index'
import { useFacilityStore } from '../facility/index'
import { useLocationStore } from '../location/index'
import { useUnitStore } from '../unit/index'
import { useEmissionStore } from '../emission/index'
import { useFeatureFlagStore } from '../featureFlag/index'
import { useUserStore } from '../user/index'
import { useEmissionTrackingTemplateStore } from '@/store/trackingTemplate'
import { UserRoleGroups } from '@/apiClient/types/user'
import { FeatureFlag } from '@/apiClient/types/featureFlag'
import { useUserSettingStore } from '../userSetting'
import { useToast } from '@/store/toast'
import { useEsgProjectStore } from '../esgProject'
import { useEsgProjectRequirementGroupStore } from '../esgProjectRequirementGroup'
import { useEsgRequirementStore } from '../esgRequirement'
import { useEsgProjectAnswerStore } from '../esgProjectAnswer'
import { useEsgRequirementGroupStore } from '../esgRequirementGroup'
import { useTaskStore } from '../task'
import { useEsgFrameworkStandardStore } from '../esgFrameworkStandard'
import { useProductLineStore } from '../productLine/index'
import { useTaskParticipantStore } from '../taskParticipant'
import { useEsgTagStore } from '../esgTag'
import { useEsgParagraphStore } from '../esgParagraph'

/**
 * Breadcrumbs configured via format { routeName: displayLabel }
 */
export type IUIBreadcrumb = {
  [routeName: string]: string
}
export const useAppStore = defineStore('app', () => {
  const orgStore = useOrgStore()
  const companyStore = useCompanyStore()
  const facilityStore = useFacilityStore()
  const productLineStore = useProductLineStore()
  const { user } = storeToRefs(useUserStore())
  const { hasFeature } = useFeatureFlagStore()
  const emissionStore = useEmissionStore()

  const fetchEsgResources = () => {
    if (!hasFeature(FeatureFlag.esgPlatform)) return Promise.all([])

    return Promise.all([
      useEsgProjectStore().fetch(),
      useEsgProjectRequirementGroupStore().fetch(),
      useEsgRequirementGroupStore().fetch(),
      useEsgRequirementStore().fetch(),
      useEsgProjectAnswerStore().fetch(),
      useTaskStore().fetch(),
      useEsgTagStore().fetch(),
      useTaskParticipantStore().fetch(),
      useEsgParagraphStore().fetch(),
      useEsgFrameworkStandardStore().fetch(),
    ])
  }

  /**
   * Fetches all resources linked to an organisation
   * - organisation info
   * - related companies
   * - related facilities
   */
  const fetchOrgResources = () => {
    return Promise.all([
      orgStore.fetchOrg(),
      companyStore.fetch({
        includeAll: true,
      }),
      facilityStore.fetch(),
      productLineStore.fetch(),
      emissionStore.fetchAll(),
      ...(hasFeature(FeatureFlag.trackingTemplate)
        ? [useEmissionTrackingTemplateStore().fetchAll()]
        : []),
    ])
  }

  /**
   * Fetch global org data
   */
  const fetchGlobalOrgData = () =>
    Promise.all([
      useLocationStore().fetch(),
      useCountryStore().fetch(),
      useUnitStore().fetchAll(),
      useFeatureFlagStore().fetch(),
      useUserSettingStore().fetch(),

      // Only if user belongs to an org (admins don't always do)
      ...(useUserStore().user?.orgId ? [fetchOrgResources()] : []),
    ]).catch((error) => {
      useToast().onError(error.message)
    })

  /**
   * Returns true if >=ADMIN is impersonating an organisation
   */
  const isImpersonatingOrg = computed(
    () =>
      user.value &&
      user.value.orgId &&
      UserRoleGroups.ADMIN_AND_ABOVE.includes(user.value.role),
  )

  return {
    // breadcrumbs,
    // setBreadcrumbs,
    // clearBreadcrumbs,
    fetchOrgResources,
    fetchGlobalOrgData,
    fetchEsgResources,
    isImpersonatingOrg,
  }
})
