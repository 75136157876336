/**
 * Capitalizes the given string
 */
export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

/**
 * Converts string into pascalCase
 */
export const toPascalCase = (str: string) =>
  str
    .split(' ')
    .map((word) => word[0].toUpperCase().concat(word.slice(1)))
    .join('')
