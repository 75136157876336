import { ICompany } from '@/apiClient/types/company'
import { defineStore, storeToRefs } from 'pinia'
import { computed } from 'vue'
import { companyService } from '@/apiClient/services'
import { createObjectsMap } from '@/utils'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { useFacilityStore } from '@/store/facility'
import { useProductLineStore } from '../productLine/index'
import { useI18n } from 'vue-i18n'

export const useCompanyStore = defineStore('company', () => {
  const socketService = useSocketsCrudFactory(companyService, 'company')

  const { facilitiesByCompanyIdMap } = storeToRefs(useFacilityStore())
  const { productLinesByCompanyIdMap } = storeToRefs(useProductLineStore())

  const { t } = useI18n()

  /**
   * List items, indexed by their id
   */
  const listItemsByIdMap = computed(() =>
    createObjectsMap(socketService.list.value, 'id'),
  )

  /**
   * Options for UIInputSelect
   */
  const companyOptions = computed(() => getOptions(companies.value))

  const getOptions = (companyArr: ICompany[]) =>
    companyArr.map(({ id, name, status }) => {
      const isDisabled = status === 'INACTIVE'
      const deactivated = isDisabled ? ` (${t('deactivatedCompany')})` : ''

      return {
        title: `${name}${deactivated}`,
        value: id,
        isDisabled,
      }
    })

  const companies = computed(() =>
    socketService.list.value.map((company) => ({
      ...company,
      _facilities: facilitiesByCompanyIdMap.value[company.id] || [],
      _productLines: productLinesByCompanyIdMap.value[company.id] || [],
    })),
  )

  /**
   * Companies, indexed by their id
   */
  const companiesByIdMap = computed(() =>
    createObjectsMap(companies.value, 'id'),
  )

  return {
    ...socketService,
    companies,
    companiesByIdMap,
    listItemsByIdMap,
    companyOptions,
    getOptions,
  }
})
