import { useUserStore } from '@/store/user'
import { FeatureFlag } from '@/apiClient/types/featureFlag'
import { featureFlagService } from '@/apiClient/services'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { UserRoleGroupsBEMap } from '@/apiClient/types/user'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useFeatureFlagStore = defineStore('featureFlag', () => {
  const crud = useSocketsCrudFactory(featureFlagService, 'featureFlag')

  const isFeatureFlagsLoaded = computed(() => crud.list.value?.length)

  const userStore = useUserStore()

  const hasFeature = (key: FeatureFlag) => {
    const featureFlag = crud.list.value?.find((flag) => flag.key === key)
    if (!featureFlag) return false

    const isRoleAllowed =
      !featureFlag?.role ||
      userStore.hasRole(UserRoleGroupsBEMap[featureFlag.role])
    const isOrgAllowed =
      featureFlag.orgIds.length === 0 ||
      (!!userStore.user?.orgId &&
        featureFlag.orgIds.includes(userStore.user.orgId))

    return !!featureFlag?.isEnabled && isRoleAllowed && isOrgAllowed
  }

  return {
    isFeatureFlagsLoaded,
    featureFlags: crud.list,
    hasFeature,
    ...crud,
  }
})
