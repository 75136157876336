import { UserRoleGroups } from '@/apiClient/types/user'
import { RouteRecordRaw } from 'vue-router'
import PassThrough from '@/views/layouts/PassThrough.vue'

export const organisationRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    meta: {
      breadcrumb: 'breadcrumbs.orgUsers',
      role: UserRoleGroups.ORGADMIN_AND_ABOVE,
    },
    component: PassThrough,
    redirect: {
      name: 'OrganisationUsers',
    },
    children: [
      {
        path: 'organisation/users',
        name: 'OrganisationUsers',
        component: () =>
          import('@/views/pages/co2/OrganisationUsers/Index.vue'),
      },
      {
        path: 'organisation/user/:id',
        name: 'OrganisationUser',
        meta: {
          breadcrumb: 'breadcrumbs.orgUser',
        },
        component: () => import('@/views/pages/co2/OrganisationUser/Index.vue'),
      },
    ],
  },
  {
    path: '/organisation/invites',
    name: 'OrganisationUserInvites',
    meta: {
      role: UserRoleGroups.ORGADMIN_AND_ABOVE,
      breadcrumb: 'orgInvites',
    },
    component: () =>
      import('@/views/pages/co2/OrganisationUserInvites/Index.vue'),
  },
]
