import { createObjectsArrayMap, createObjectsMap } from '@/utils'
import { facilityService } from '@/apiClient/services'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { useCompanyStore } from '../company'
import { ICompany } from '@/apiClient/types/company'
import { useI18n } from 'vue-i18n'

export const useFacilityStore = defineStore('facility', () => {
  const socketService = useSocketsCrudFactory(facilityService, 'facility')
  const companyStore = useCompanyStore() as {
    listItemsByIdMap: Record<string, ICompany>
  }

  const { t } = useI18n()

  /**
   * Facilities, indexed by their companyId
   */
  const facilitiesByCompanyIdMap = computed(() =>
    createObjectsArrayMap(socketService.list.value, 'companyId'),
  )

  /**
   * Options for UIInputSelect
   */
  const facilityOptions = computed(
    () =>
      facilities.value?.map((facility) => {
        const isDisabled = facility.status === 'INACTIVE'
        const deactivated = isDisabled ? ` (${t('deactivatedFacility')})` : ''

        return {
          title: `${facility.name}${deactivated}`,
          value: facility.id,
          raw: facility,
          isDisabled,
        }
      }) || [],
  )

  const facilities = computed(() =>
    socketService.list.value.map((facility) => ({
      ...facility,
      companyName: companyStore.listItemsByIdMap[facility.companyId]?.name,
      _company: companyStore.listItemsByIdMap[facility.companyId],
    })),
  )

  /**
   * Facilities, indexed by their id
   */
  const facilitiesByIdMap = computed(() =>
    createObjectsMap(facilities.value, 'id'),
  )

  return {
    ...socketService,
    facilities,
    facilitiesByIdMap,
    facilitiesByCompanyIdMap,
    facilityOptions,
  }
})
