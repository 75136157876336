import { RouteRecordRaw } from 'vue-router'
import { addRoutePrefix } from '../utils'
import { settingsRoutes } from './modules/settings'
import { FeatureFlag } from '@/apiClient/types/featureFlag'

export const esgRoutes: RouteRecordRaw[] = [
  {
    path: '/reports',
    name: 'EsgReports',
    component: () => import('@/views/pages/esg/Reports/Index.vue'),
  },
  {
    path: '/market-insights',
    name: 'MarketInsights',
    component: () => import('@/views/pages/co2/MarketInsights/Index.vue'),
    meta: {
      featureFlag: FeatureFlag.marketInsights,
    },
  },
  {
    path: '/indicators',
    name: 'EsgGlobalIndicator',
    component: () => import('@/views/pages/esg/Indicators/Index.vue'),
  },
  {
    path: '/topics',
    name: 'EsgTopics',
    component: () => import('@/views/pages/esg/Topics/Index.vue'),
  },
  {
    path: '/disclosures',
    name: 'EsgDisclosures',
    component: () => import('@/views/pages/esg/Disclosures/Index.vue'),
  },
  {
    path: '/requirements',
    name: 'EsgRequirements',
    component: () => import('@/views/pages/esg/Requirements/Index.vue'),
  },
  {
    path: '/projects',
    component: () => import('@/views/layouts/EsgProject.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'EsgDashboard',
        component: () => import('@/views/pages/esg/Dashboard/Index.vue'),
      },
      {
        path: '/projects/:framework',
        name: 'EsgProjects',
        component: () => import('@/views/pages/esg/Projects/Index.vue'),
      },
      {
        path: '/project/:projectId',
        name: 'EsgProject',
        component: () => import('@/views/pages/esg/Project/Index.vue'),
      },
      {
        path: '/project/:projectId/report',
        name: 'EsgProjectReport',
        component: () => import('@/views/pages/esg/ProjectReport/Index.vue'),
      },
    ],
  },
  {
    path: '/my-team',
    name: 'EsgMyTeam',
    redirect: {
      name: 'EsgMyTeamMembers',
    },
    component: () => import('@/views/pages/esg/SettingsMyTeam/Index.vue'),
    children: [
      {
        path: '/my-team/members',
        name: 'EsgMyTeamMembers',
        component: () =>
          import('@/views/pages/esg/SettingsMyTeamMembers/Index.vue'),
      },
      {
        path: '/my-team/invites',
        name: 'EsgMyTeamInvites',
        component: () =>
          import('@/views/pages/esg/SettingsMyTeamMembersInvites/Index.vue'),
      },
    ],
  },
  ...settingsRoutes,
].map(addRoutePrefix('esg', { onlyWithAllOrgAccess: true }))
