<template>
  <div id="LayoutAuth">
    <img class="LayoutAuth__logo" src="/logo.svg" />

    <RouterView />
  </div>
</template>

<script lang="ts">
import { useFeatureFlagStore } from '@/store/featureFlag'
import { defineComponent, onMounted } from 'vue'

const LayoutAuth = defineComponent({
  name: 'LayoutAuth',
  setup() {
    onMounted(() => {
      useFeatureFlagStore().fetch()
    })
  },
})

export default LayoutAuth
</script>

<style scoped lang="scss">
#LayoutAuth {
  min-height: 100vh;
  width: 100vw;
  background-color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .LayoutAuth__logo {
    margin-bottom: 1.5rem;
    width: 16rem;
  }
}
</style>
