<template>
  <ul class="TheSidebarMenuGroup">
    <slot />
  </ul>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.TheSidebarMenuGroup {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
