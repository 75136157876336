import { defineStore } from 'pinia'
import { esgFrameworkStandardService } from '@/apiClient/services'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useEsgFrameworkStandardStore = defineStore(
  'esgFrameworkStandard',
  () => {
    const socketService = useSocketsCrudFactory(
      esgFrameworkStandardService,
      'esgFrameworkStandard',
    )

    return {
      ...socketService,
    }
  },
)
