import { useI18n } from 'vue-i18n'
import { transportTypeService } from '@/apiClient/services'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useTransportTypeStore = defineStore('transportType', () => {
  const { t } = useI18n()
  const crud = useSocketsCrudFactory(transportTypeService, 'transportType')
  const transportTypesTranslated = computed(() =>
    crud.list.value.map((item) => ({ ...item, name: t(item.name) })),
  )

  /**
   * Options for UIInputSelect
   */
  const transportTypeOptions = computed(() =>
    transportTypesTranslated.value.map(({ id, name }) => ({
      title: name,
      value: id,
    })),
  )

  return {
    transportTypes: transportTypesTranslated,
    transportTypeOptions,
    ...crud,
  }
})
