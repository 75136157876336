import { energyCoefficientService } from '@/apiClient/services'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { createObjectsArrayMap } from '@/utils'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useEnergyCoefficientStore = defineStore(
  'energyCoefficient',
  () => {
    const crud = useSocketsCrudFactory(
      energyCoefficientService,
      'energyCoefficient',
    )

    const energyCoefficientsBySourceIdMap = computed(() =>
      createObjectsArrayMap(crud.list.value, 'emissionSourceId'),
    )

    return {
      energyCoefficients: crud.list,
      energyCoefficientsBySourceIdMap,
      ...crud,
    }
  },
)
