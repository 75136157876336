import { defineStore } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { esgProjectAnswerService } from '../../apiClient/services'
import { computed } from 'vue'
import { createObjectsArrayMap } from '@/utils'

export const useEsgProjectAnswerStore = defineStore('esgProjectAnswer', () => {
  const socketService = useSocketsCrudFactory(
    esgProjectAnswerService,
    'esgProjectAnswer',
  )
  // if (!socketService.isInitialised.value) {
  //   socketService.fetch()
  // }

  const esgProjectAnswers = computed(() => socketService.list.value)

  /**
   * Map of ESG Project Answers, indexed by ESG Project ID
   * @type {ComputedRef<Record<string, EsgProjectAnswer[]>>}
   */
  const esgProjectAnswerByProjectMap = computed(() =>
    createObjectsArrayMap(esgProjectAnswers.value, 'esgProjectId'),
  )

  return {
    ...socketService,
    esgProjectAnswers,
    esgProjectAnswerByProjectMap,
  }
})
