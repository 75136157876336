import { transportTypeEmissionSourceService } from '@/apiClient/services'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { createPropertyMap, createObjectsArrayMap } from '@/utils'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useTransportTypeEmissionSourceStore = defineStore(
  'transportTypeEmissionSource',
  () => {
    const crud = useSocketsCrudFactory(
      transportTypeEmissionSourceService,
      'transportTypeEmissionSource',
    )

    const transportTypesEmissionSourcesGrouped = computed(() =>
      createObjectsArrayMap(crud.list.value, 'transportTypeId'),
    )

    const transportTypeIdByEmissionSourceId = computed(() =>
      createPropertyMap(crud.list.value, 'emissionSourceId', 'transportTypeId'),
    )

    return {
      transportTypesEmissionSources: crud.list,
      transportTypesEmissionSourcesGrouped,
      transportTypeIdByEmissionSourceId,
      ...crud,
    }
  },
)
