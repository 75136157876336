<template>
  <div class="UIBlockContent">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const UIBlockContent = defineComponent({
  name: 'UIBlockContent',
})

export default UIBlockContent
</script>

<style scoped lang="scss">
.UIBlockContent {
}
</style>
