import { UserRoleGroups } from '@/apiClient/types/user'
import { RouteRecordRaw } from 'vue-router'

export const adminRoutes: RouteRecordRaw[] = [
  {
    name: 'AdminTools',
    path: '/admin',
    meta: {
      role: UserRoleGroups.ADMIN_AND_ABOVE,
      breadcrumbs: 'Admin',
    },
    children: [
      {
        path: '/admin/organisations',
        name: 'Organisations',
        meta: {
          breadcrumbs: 'organisations',
        },
        component: () =>
          import('@/views/pages/co2/AdminOrganisations/Index.vue'),
      },
      {
        path: '/admin/organisation/invites',
        name: 'OrganisationInvites',
        meta: {
          breadcrumbs: 'orgInvites',
        },
        component: () =>
          import('@/views/pages/co2/AdminOrganisationInvites/Index.vue'),
      },
      {
        path: '/admin/users',
        name: 'Users',
        meta: {
          breadcrumbs: 'users',
        },
        component: () => import('@/views/pages/co2/AdminUsers/Index.vue'),
      },
      {
        path: '/admin/feature-flags',
        name: 'FeatureFlags',
        meta: {
          breadcrumbs: 'featureFlags',
        },
        component: () =>
          import('@/views/pages/co2/AdminFeatureFlags/Index.vue'),
      },
      {
        path: '/admin/action-logs',
        name: 'ActionLogs',
        meta: {
          breadcrumbs: 'actionLogs',
        },
        component: () => import('@/views/pages/co2/AdminActionLogs/Index.vue'),
      },
    ],
  },
]
