<template>
  <div class="UIChat">
    <UIButtonIcon
      class="UIChat__chat-head"
      @click="() => (showChat = true)"
      :icon="icon"
    />
    <transition name="slide-y-transition">
      <UIChatContainer
        v-if="showChat"
        :chatItems="chatItems"
        v-model="inputText"
        @sendMessage="sendMessage"
        :isLoading="isLoading"
        @close="() => (showChat = false)"
      >
        <template #header>
          <slot name="header" />
        </template>
      </UIChatContainer>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { IIcons } from '@/views/components/ui/icons/types'
import UIButtonIcon from '@/views/components/ui/buttons/UIButtonIcon.vue'
import { IChatItem } from './components/UIChatArea.vue'
import UIChatContainer from './components/UIChatContainer.vue'

defineProps({
  icon: {
    type: String as PropType<IIcons>,
    default: 'robotHappy',
  },
  chatItems: {
    type: Array as PropType<IChatItem[]>,
    required: true,
  },
  isLoading: {
    type: Boolean,
  },
})

const emit = defineEmits(['sendMessage'])

const sendMessage = async () => {
  if (!inputText.value.trim()) return
  emit('sendMessage', inputText.value)
  inputText.value = ''
}

const inputText = ref('')
const showChat = ref(false)
</script>

<style lang="scss" scoped>
.UIChat {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  bottom: 1rem;
  right: 1rem;

  &__chat-head {
    padding: 0.5rem;
    background: $newcolor-primary-main;
    border-radius: 50%;
    display: flex;
    align-items: center;
    line-height: 1.25rem;

    --size: 1.75rem;
    --icon-color: #{$color-white};
    &:before {
      background-color: #{$newcolor-primary-light};
    }
    &:hover {
      --icon-color: #{$color-white};
    }
  }

  :deep(.UIChatContainer) {
    position: fixed;
    z-index: 1;
    max-width: 20rem;
    height: 25rem;
    right: 1rem;
    bottom: 1rem;
  }
}
</style>
