import { HookContext } from '@feathersjs/feathers'

export default {
  before: {
    all: [],
  },
  after: {
    all: [],
  },
  error: {
    all: [
      (ctx: HookContext) => {
        if (ctx.error?.code === 401 && window.location.pathname !== '/login') {
          window.location.replace('/logout')
        }
      },
    ],
  },
}
