<template>
  <UIBlock size="none" class="UIChatContainer">
    <template #title>
      <UIChatHeader :hideCloseIcon="hideCloseIcon" @close="emit('close')">
        <slot name="header"> Chat </slot>
      </UIChatHeader>
    </template>
    <template #content>
      <slot name="above-chat" />
      <UIChatArea
        ref="chatArea"
        :chatItems="chatItems"
        :isLoading="isLoading"
      />
      <slot name="below-chat" />
      <UIChatInput
        :modelValue="modelValue"
        @update:modelValue="emit('update:modelValue', $event)"
        @sendMessage="emit('sendMessage')"
        :isDisabled="isLoading || isDisabled"
      />
    </template>
  </UIBlock>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { IIcons } from '../../../icons/types'
import UIChatArea, { IChatItem } from './UIChatArea.vue'
import UIBlock from '../../../containers/UIBlock/UIBlock.vue'
import UIChatHeader from './UIChatHeader.vue'
import UIChatInput from './UIChatInput.vue'

const emit = defineEmits(['close', 'sendMessage', 'update:modelValue'])
const chatArea = ref<InstanceType<typeof UIChatArea>>()

defineProps({
  hideCloseIcon: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
  },
  icon: {
    type: String as PropType<IIcons>,
    default: 'robotHappy',
  },
  chatItems: {
    type: Array as PropType<IChatItem[]>,
    required: true,
  },
  isLoading: {
    type: Boolean,
  },
  isDisabled: {
    type: Boolean,
  },
})
</script>

<style scoped lang="scss">
.UIChatContainer {
  background: $color-white;
  width: 100%;

  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  :deep(.UIBlock__title-container) {
    z-index: 1;
    box-shadow: 0 0.125rem 0.5rem -2px $newcolor-primary-main;
  }

  :deep(.UIBlockTitle) {
    margin: 0;
  }

  :deep(.UIBlock__title-container) {
    padding: 0;
  }

  :deep(.UIBlockContent) {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
  }
}
</style>
