import { useLocale } from './useLocale'

export const useFormatters = () => {
  const { getLocaleShortCode } = useLocale()

  /**
   * Formats the given number according to the user locale
   * @param value Number to be formatted
   * @param opts (Optional) Intl.NumberFormatOptions
   * @param {boolean} opts.dynamicMaximumFractionDigits - If true, precision is adapted based on the value's decimal part.
   * @returns Formatted number
   */
  const formatNumber = (
    value: number,
    precision = 2,
    opts?: Intl.NumberFormatOptions & {
      dynamicMaximumFractionDigits?: boolean
    },
  ) => {
    const number =
      (value && precision && parseFloat(value?.toFixed(precision))) ||
      value ||
      0

    const getPrecision = () => {
      if (!opts?.dynamicMaximumFractionDigits) return precision

      const fractionPart = String(value).split('.')[1]
      if (!fractionPart) return precision

      const dynamicPrecision = Array.from(fractionPart).findIndex(
        (char) => char !== '0',
      )

      return dynamicPrecision + precision
    }

    return new Intl.NumberFormat(getLocaleShortCode(), {
      ...opts,
      maximumFractionDigits: getPrecision(),
    }).format(number)
  }

  /**
   * Parse a localized number to a float.
   * @param {string} stringNumber - the localized number
   */
  const parseNumber = (stringNumber: string) => {
    const locale = getLocaleShortCode()
    const thousandSeparator = Intl.NumberFormat(locale)
      .format(11111)
      .replace(/\p{Number}/gu, '')
    const decimalSeparator = Intl.NumberFormat(locale)
      .format(1.1)
      .replace(/\p{Number}/gu, '')

    return parseFloat(
      stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.'),
    )
  }

  /**
   * Formats the given date according to the user locale
   * @param value Date to be formatted
   * @param opts (Optional) Intl.DateTimeFormatOptions
   * @returns Formatted date
   */
  const formatDate = (
    date: Date | string,
    opts?: Intl.DateTimeFormatOptions,
  ) => {
    const realDate = typeof date === 'string' ? new Date(date) : date

    return new Intl.DateTimeFormat(getLocaleShortCode(), opts).format(realDate)
  }

  return {
    formatNumber,
    parseNumber,
    formatDate,
  }
}
