import { emissionTrackingTemplateSourceService } from '@/apiClient/services'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { defineStore } from 'pinia'

export const useEmissionTrackingTemplateSourceStore = defineStore(
  'trackingTemplateSource',
  () => {
    const crud = useSocketsCrudFactory(
      emissionTrackingTemplateSourceService,
      'trackingTemplateSource',
    )

    return {
      emissionTrackingTemplateSources: crud.list,
      ...crud,
    }
  },
)
