import { useI18n } from 'vue-i18n'
import { countryService } from '@/apiClient/services'
import { createObjectsMap } from '@/utils'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useCountryStore = defineStore('country', () => {
  const crud = useSocketsCrudFactory(countryService, 'country')

  const { t } = useI18n()
  const countryTranslated = computed(() =>
    crud.list.value
      .map((item) => ({ ...item, name: t(item.name) }))
      .sort((a, b) => a.name.localeCompare(b.name)),
  )

  /**
   * Options for UIInputSelect
   */
  const countryOptions = computed(() =>
    countryTranslated.value.map(({ id, name }) => ({
      title: name,
      value: id,
    })),
  )

  /**
   * Object with { countryId: countryName }
   */
  const countriesIdMap = computed(() =>
    createObjectsMap(countryTranslated.value, 'name'),
  )

  return {
    countries: countryTranslated,
    countryOptions,
    countriesIdMap,
    ...crud,
  }
})
