import { emissionFactorService } from '@/apiClient/services'
import {
  EMISSION_FACTOR_TYPES,
  IEmissionFactor,
} from '@/apiClient/types/emissions'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { groupItemsByNestedKeys } from '@/utils'
import { defineStore } from 'pinia'
import { computed } from 'vue'

interface IEmissionSourceFactorFilters {
  type?: EMISSION_FACTOR_TYPES
  version?: number
  meta?: {
    [key: string]: string | number
  }
}

// Minimum year for emission factor version
const BASE_YEAR = 2020

export const useEmissionFactorStore = defineStore('emissionFactor', () => {
  const crud = useSocketsCrudFactory(emissionFactorService, 'emissionFactor')

  const getFactorBySourceIds = (
    ids: number | number[],
    filters: IEmissionSourceFactorFilters = {
      type: EMISSION_FACTOR_TYPES.BASE,
    },
  ): IEmissionFactor | undefined => {
    const emissionSourceIds = Array.isArray(ids) ? ids : [ids]

    const emissionFactor = crud.list.value.find(
      (emissionFactor) =>
        emissionSourceIds.includes(emissionFactor.emissionSourceId) &&
        (filters.type ? emissionFactor.type === filters.type : true) &&
        (filters.version
          ? emissionFactor.version === filters.version.toString()
          : true) &&
        Object.entries(filters.meta || {}).every(([key, value]) => {
          return (
            emissionFactor.meta &&
            emissionFactor.meta[key] &&
            emissionFactor.meta[key] === value
          )
        }),
    )

    if (emissionFactor) return emissionFactor

    if (filters.version && filters.version > BASE_YEAR) {
      return getFactorBySourceIds(emissionSourceIds, {
        ...filters,
        version: filters.version - 1,
      })
    } else {
      return
    }
  }

  const emissionFactorsMap = computed(
    () =>
      groupItemsByNestedKeys(crud.list.value, [
        'emissionSourceId',
        'type',
        'version',
      ]) as Record<
        number,
        Record<EMISSION_FACTOR_TYPES, Record<string, IEmissionFactor[]>>
      >,
  )

  const refillEmissionSourceFactors = async (sourceId: number) => {
    const sourceEmissionFactors = await crud.find({
      emissionSourceId: sourceId,
      prefillFactors: true,
    })

    crud.list.value = [
      ...crud.list.value.filter(
        ({ emissionSourceId }) => emissionSourceId !== sourceId,
      ),
      ...sourceEmissionFactors.data,
    ]
  }

  return {
    ...crud,
    emissionFactors: crud.list,
    getFactorBySourceIds,
    emissionFactorsMap,
    refillEmissionSourceFactors,
  }
})
