import { LOCALES } from '@/i18n'

export enum UserRoles {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  ORGADMIN = 'ORGADMIN',
  ADVANCED_USER = 'ADVANCED_USER',
  USER = 'USER',
}

export const UserRoleGroups = {
  SUPERADMIN: [UserRoles.SUPERADMIN],
  ADMIN: [UserRoles.ADMIN],
  ORGADMIN: [UserRoles.ORGADMIN],
  USER: [UserRoles.USER],
  ADVANCED_USER: [UserRoles.ADVANCED_USER],
  // Above
  USER_AND_ABOVE: [
    UserRoles.USER,
    UserRoles.ADVANCED_USER,
    UserRoles.ORGADMIN,
    UserRoles.ADMIN,
    UserRoles.SUPERADMIN,
  ],
  ADVANCED_USER_AND_ABOVE: [
    UserRoles.ADVANCED_USER,
    UserRoles.ORGADMIN,
    UserRoles.ADMIN,
    UserRoles.SUPERADMIN,
  ],
  ORGADMIN_AND_ABOVE: [
    UserRoles.ORGADMIN,
    UserRoles.ADMIN,
    UserRoles.SUPERADMIN,
  ],
  ADMIN_AND_ABOVE: [UserRoles.ADMIN, UserRoles.SUPERADMIN],
  // Under
  ADMIN_AND_UNDER: [
    UserRoles.ADMIN,
    UserRoles.ORGADMIN,
    UserRoles.ADVANCED_USER,
    UserRoles.USER,
  ],
  ORGADMIN_AND_UNDER: [
    UserRoles.ORGADMIN,
    UserRoles.ADVANCED_USER,
    UserRoles.USER,
  ],
  ADVANCED_USER_AND_UNDER: [UserRoles.ADVANCED_USER, UserRoles.USER],
}

export const UserRoleGroupsBEMap = {
  SUPERADMIN: [UserRoles.SUPERADMIN],
  ADMIN: [UserRoles.ADMIN],
  ORGADMIN: [UserRoles.ORGADMIN],
  USER: [UserRoles.USER],
  ADVANCED_USER: [UserRoles.ADVANCED_USER],

  // Above
  '>=USER': [
    UserRoles.USER,
    UserRoles.ADVANCED_USER,
    UserRoles.ORGADMIN,
    UserRoles.ADMIN,
    UserRoles.SUPERADMIN,
  ],
  '>=ORGADMIN': [UserRoles.ORGADMIN, UserRoles.ADMIN, UserRoles.SUPERADMIN],
  '>=ADVANCED_USER': [
    UserRoles.ADVANCED_USER,
    UserRoles.ORGADMIN,
    UserRoles.ADMIN,
    UserRoles.SUPERADMIN,
  ],
  '>=ADMIN': [UserRoles.ADMIN, UserRoles.SUPERADMIN],
  // Under
  '<=ADMIN': [UserRoles.ADMIN, UserRoles.ORGADMIN, UserRoles.USER],
  '<=ORGADMIN': [UserRoles.ORGADMIN, UserRoles.USER],
}

export interface IUser {
  id: number
  firstName: string
  lastName: string
  email: string
  isVerified: boolean
  role: UserRoles
  orgId?: number
  companyId?: number
  facilityId?: number
  locale: LOCALES
  createdAt: string
  updatedAt: string
  hasAcceptedPolicy: boolean
  password?: string
  passwordConfirm?: string
  userAccess: (IUserAccess | IUserAccessData)[]
}

export interface IUserAccess extends IUserAccessData {
  id: number
  userId: number
}
export interface IUserAccessData {
  userId?: number
  companyId: number
}

export enum USER_SETTING_KEY {
  ENERGY_INTENSITY_UNIT_MULTIPLIER_ID = 'energyIntensityUnitMultiplierId',
}

export interface IUserSettingData {
  userId: number
  key: USER_SETTING_KEY
  value: string | null
}

export interface IUserSetting extends IUserSettingData {
  id: number
}
