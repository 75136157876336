export enum ASSISTANT_CHAT_ROLE {
  ASSISTANT = 'assistant',
  USER = 'user',
}

export interface IAssistantChatItem {
  id: number
  role: ASSISTANT_CHAT_ROLE
  content: string
}
