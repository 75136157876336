import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import client from '@/apiClient'
import { useRouter } from 'vue-router'
import { IUser, UserRoles } from '@/apiClient/types/user'
import { userService } from '@/apiClient/services'
import { IOrganisation } from '@/apiClient/types/organisation'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useUserStore = defineStore('user', () => {
  const user = ref<Nullable<IUser>>(undefined)

  const isLoggedIn = computed(() => !!user.value)

  const hasAllCompaniesAccess = computed(() => !user.value?.userAccess?.length)

  function setUser(usr?: IUser) {
    user.value = usr
  }

  const register = async (
    user: Pick<IUser, 'email' | 'password' | 'passwordConfirm'>,
  ) => {
    return userService.create({
      // TODO: temporarily hardcoded
      hasAcceptedPolicy: true,
      ...user,
    })
  }

  const login = async ({
    email,
    password,
  }: Pick<IUser, 'email' | 'password'>) => {
    const user = await client
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .then((authResult) => {
        if (!authResult) throw new Error('Invalid authentication result')

        return authResult.user
      })
    setUser(user)
  }
  const reAuthenticate = async (force = false) => {
    try {
      const { user } = await client.reAuthenticate(force)
      setUser(user)
    } catch (err) {
      if (localStorage.getItem('susjwt')) {
        localStorage.removeItem('susjwt')
        location.reload()
      }
      throw err
    }
  }
  const logout = async () => {
    const router = useRouter()
    if (!isLoggedIn.value) {
      router.push({ name: 'Login' })
      return
    }

    await client.logout().finally(() => {
      setUser()
      router.push({ name: 'Login' })
    })
  }
  const hasRole = (roleGroup: UserRoles[]) =>
    user.value ? roleGroup.includes(user.value?.role) : false

  const setOrgId = (org: IOrganisation) => {
    if (!user.value) throw new Error('User not found.')

    return userService.patch(user.value?.id, {
      orgId: org.id,
    })
  }

  const unsetOrgId = () => {
    if (!user.value) throw new Error('User not found.')

    return userService.patch(user.value?.id, {
      orgId: undefined,
    })
  }

  const patch = (id: number, override: Partial<IUser>) => {
    userService.patch(id, override)
  }

  return {
    user,
    patch,
    isFetching: userService.isFetching,
    isLoggedIn,
    hasAllCompaniesAccess,
    setUser,
    login,
    reAuthenticate,
    logout,
    hasRole,
    register,
    setOrgId,
    unsetOrgId,
  }
})
