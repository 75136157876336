import { RouteRecordRaw } from 'vue-router'

export const settingsRoutes: RouteRecordRaw[] = [
  {
    path: '/settings',
    meta: {
      breadcrumb: 'settings',
    },
    children: [
      {
        path: '/settings',
        name: 'EsgSettings',
        component: () => import('@/views/pages/esg/Settings/Index.vue'),
      },
      {
        path: '/settings/organisation',
        name: 'EsgSettingsOrganisation',
        meta: {
          breadcrumb: 'organisations',
        },
        component: () =>
          import('@/views/pages/esg/SettingsOrganisation/Index.vue'),
      },
      {
        path: '/settings/profile',
        name: 'EsgSettingsProfile',
        meta: {
          breadcrumb: 'profile',
        },
        component: () => import('@/views/pages/esg/SettingsProfile/Index.vue'),
      },
      {
        path: '/settings/companies',
        name: 'EsgSettingsCompanies',
        meta: {
          breadcrumb: 'companies',
        },
        component: () =>
          import('@/views/pages/esg/SettingsCompanies/Index.vue'),
      },
      {
        path: '/settings/facilities',
        name: 'EsgSettingsFacilities',
        meta: {
          breadcrumb: 'facilities',
        },
        component: () =>
          import('@/views/pages/esg/SettingsFacilities/Index.vue'),
      },
      {
        path: '/settings/org-structure',
        name: 'EsgSettingsOrgStructure',
        redirect: {
          name: 'EsgSettingsOrgStructureCompanies',
        },
        component: () =>
          import('@/views/pages/esg/SettingsOrgStructure/Index.vue'),
        children: [
          {
            path: '/settings/org-structure/companies',
            name: 'EsgSettingsOrgStructureCompanies',
            meta: {
              breadcrumb: 'companies',
            },
            component: () =>
              import(
                '@/views/pages/esg/SettingsOrgStructureCompanies/Index.vue'
              ),
          },
          {
            path: '/settings/org-structure/facilities',
            name: 'EsgSettingsOrgStructureFacilities',
            meta: {
              breadcrumb: 'facilities',
            },
            component: () =>
              import(
                '@/views/pages/esg/SettingsOrgStructureFacilities/Index.vue'
              ),
          },
        ],
      },
      {
        path: '/settings/my-team',
        name: 'EsgSettingsMyTeam',
        redirect: {
          name: 'EsgSettingsMyTeamMembers',
        },
        component: () => import('@/views/pages/esg/SettingsMyTeam/Index.vue'),
        children: [
          {
            path: '/settings/my-team/members',
            name: 'EsgSettingsMyTeamMembers',
            meta: {
              breadcrumb: 'teamMembers',
            },
            component: () =>
              import('@/views/pages/esg/SettingsMyTeamMembers/Index.vue'),
          },
          {
            path: '/settings/my-team/invites',
            name: 'EsgSettingsMyTeamInvites',
            meta: {
              breadcrumb: 'userInvites',
            },
            component: () =>
              import(
                '@/views/pages/esg/SettingsMyTeamMembersInvites/Index.vue'
              ),
          },
        ],
      },
    ],
  },
]
