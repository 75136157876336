import feathers, { Params, Service, Application } from '@feathersjs/feathers'
import authentication from '@feathersjs/authentication-client'
import hooks from './hooks'
import rest from './rest'
import sockets from './sockets'
import { apiBase, jwtName } from '@/config'
import { AuthenticationRequest } from '@feathersjs/authentication'

const configureClient = (clientConfigFunction: (app: Application) => void) =>
  feathers()
    .configure(clientConfigFunction)
    .configure(
      authentication({
        path: `${apiBase}/authentication`,
        storageKey: jwtName,
      }),
    )
    .hooks(hooks)

const clientSockets = configureClient(sockets)
const clientRest = configureClient(rest)

const apiClient = {
  logout: () => Promise.all([clientSockets.logout(), clientRest.logout()]),
  authenticate: async (
    authentication?: AuthenticationRequest,
    params?: Params,
  ) => {
    return (
      await Promise.all([
        clientSockets.authenticate(authentication, params),
        clientRest.authenticate(authentication, params),
      ])
    )[0]
  },
  reAuthenticate: async (force: boolean) => {
    return (
      await Promise.all([
        clientSockets.reAuthenticate(force),
        clientRest.reAuthenticate(force),
      ])
    )[0]
  },
  service: (name: string) => clientSockets.service(name),
}

// The Client REST API is utilized for the 'organisationImportExport' and 'upload' services.
// We chose this approach because file uploads encounter issues when using socket-based communication.
export const getService = <T>(name: string): Service<T> => {
  if (['organisationImportExport', 'upload'].includes(name)) {
    return clientRest.service(`${apiBase}/${name}`)
  }

  return apiClient.service(`${apiBase}/${name}`)
}

export default apiClient
