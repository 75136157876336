import { useIntensityMetricRecordStore } from './../intensityMetricRecord/index'
import { useIntensityMetricStore } from './../intensityMetric/index'
import { bulkCrud } from './../../utils/helpers'
import {
  EMISSIONS_ENERGY,
  EMISSION_FACTOR_TYPES,
  IEmissionFactor,
  IEmissionFactorData,
  IEmissionSourceWithFactors,
  IEmissionSourceWithFactorsData,
  IEmissionValueWithMeta,
  IEnergyCoefficient,
} from './../../apiClient/types/emissions'
import { defineStore } from 'pinia'
import { useEmissionCategoryStore } from '../emissionCategory'
import { useEmissionSourceStore } from '../emissionSource'
import { useEmissionFactorStore } from '../emissionFactor'
import { computed, ref } from 'vue'
import {
  IEmissionValue,
  IEmissionSourceVersionedFlattened,
  EMISSIONS,
  IEmissionSource,
  IEmissionCategory,
  EMISSION_TYPES,
} from '@/apiClient/types/emissions'
import { useEnergyCoefficientStore } from '../energyCoefficient'
import { useTransportTypeEmissionSourceStore } from '../transportTypeEmissionSource'
import { useTransportTypeStore } from '../transportType'

export const useEmissionStore = defineStore('emission', () => {
  const emissionCategoryStore = useEmissionCategoryStore()
  const emissionSourceStore = useEmissionSourceStore()
  const emissionFactorStore = useEmissionFactorStore()
  const energyCoefficientStore = useEnergyCoefficientStore()
  const intensityMetricsStore = useIntensityMetricStore()
  const intensityMetricsRecordStore = useIntensityMetricRecordStore()
  const transportTypesEmissionSourcesStore =
    useTransportTypeEmissionSourceStore()
  const transportTypeStore = useTransportTypeStore()

  const fetchAll = () => {
    return Promise.all([
      // TODO: handle limits
      emissionCategoryStore.fetch(),
      emissionSourceStore.fetch({ $sort: { name: 1 } }),
      emissionFactorStore.fetch({
        prefillFactors: true,
      }),
      energyCoefficientStore.fetch({
        prefillEnergyCoefficients: true,
      }),
      intensityMetricsStore.fetch(),
      intensityMetricsRecordStore.fetch(),
      transportTypeStore.fetch(),
      transportTypesEmissionSourcesStore.fetch(),
    ])
  }

  const refetchEmissionSources = () =>
    emissionSourceStore.fetch({ $sort: { id: -1 } })

  // Composed relationships

  const flattenFactors = (factors: IEmissionFactor[]) => {
    return factors.reduce((accum, factor) => {
      // If energy, then group by location
      if (
        !!factor.meta &&
        'locationId' in factor.meta &&
        'type' in factor.meta &&
        factor.meta.type
      ) {
        accum[factor.meta.type] = {
          ...(accum[factor.meta.type] || {}),
          [factor.meta.locationId]: factor.value,
        }
      } else {
        accum[factor.emissions] = factor.value
        if (
          factor.emissions === EMISSIONS.CH4 ||
          factor.emissions === EMISSIONS.N2O
        ) {
          accum[`${factor.emissions}_CO2E`] = factor.valueCo2e
        }
      }

      return accum
    }, {} as IEmissionValueWithMeta)
  }

  const flattenFactorsMap = (
    emissionFactorsMap: Record<
      EMISSION_FACTOR_TYPES,
      Record<string, IEmissionFactor[]>
    >,
  ): IEmissionFactor[] => {
    let factors: IEmissionFactor[] = []

    // eslint-disable-next-line guard-for-in
    for (const outerKey in emissionFactorsMap) {
      const innerMap = emissionFactorsMap[outerKey as EMISSION_FACTOR_TYPES]
      // eslint-disable-next-line guard-for-in
      for (const innerKey in innerMap) {
        factors = factors.concat(innerMap[innerKey])
      }
    }

    return factors
  }

  const emissionSourcesWithPrefilledFactors = computed<
    IEmissionSourceWithFactorsData[]
  >(() => {
    if (!emissionFactorStore.emissionFactors.length) return []

    return emissionSourceStore.emissionSources.map((emissionSource) => {
      const emissionFactorsMap =
        emissionFactorStore.emissionFactorsMap[emissionSource.id] || {}

      const factors = flattenFactorsMap(emissionFactorsMap)

      return {
        ...emissionSource,
        emissionFactors: factors,
        energyCoefficients:
          energyCoefficientStore.energyCoefficientsBySourceIdMap[
            emissionSource.id
          ],
      }
    })
  })

  /**
   * Emission sources with versioned and flattened values (as factor properties)
   */
  const emissionSourcesWithVersionedFactors = computed<
    IEmissionSourceVersionedFlattened[]
  >(() => {
    return emissionSourcesWithPrefilledFactors.value.map((emissionSource) => {
      const factors: Record<string, IEmissionValue> = Object.entries(
        (emissionSource.id &&
          emissionFactorStore.emissionFactorsMap[emissionSource.id]?.BASE) ||
          {},
      ).reduce(
        (accum, [year, factors]) => ({
          ...accum,
          [year]: flattenFactors(factors),
        }),
        {},
      )

      return {
        ...emissionSource,
        emissionFactorsFlatByVersion: factors,
      } as IEmissionSourceVersionedFlattened
    })
  })

  /**
   * Emission categories with emission sources
   */
  const emissionCategories = computed(() =>
    emissionCategoryStore.emissionCategories.map((emissionCategory) => ({
      ...emissionCategory,
      emissionSources: emissionSourceStore.emissionSources.filter(
        (emissionSource) =>
          emissionSource.emissionCategoryId === emissionCategory.id,
      ),
    })),
  )

  /**
   * Emission scopes with categories and emission sources
   */
  const emissionScopes = computed<Record<number, IEmissionCategory[]>>(() =>
    [1, 2, 3].reduce((accum, scope) => {
      const scopeCategories = emissionCategories.value.filter(
        (category) => category.scope === scope,
      )

      return {
        ...accum,
        [scope]: scopeCategories,
      }
    }, {}),
  )

  const emissionsOptions = computed(() => [
    {
      value: EMISSIONS.CO2,
      title: 'CO2',
      emissionTypes: [
        EMISSION_TYPES.FUEL,
        EMISSION_TYPES.HEATING,
        EMISSION_TYPES.REFRIGERANT,
        EMISSION_TYPES.SPEND_BASED,
        EMISSION_TYPES.TRANSPORT,
        EMISSION_TYPES.WATER,
        EMISSION_TYPES.WASTE,
        EMISSION_TYPES.MATERIAL,
        EMISSION_TYPES.TRANSPORT_UPSTREAM,
        EMISSION_TYPES.TRANSPORT_DOWNSTREAM,
        EMISSION_TYPES.ENERGY,
        EMISSION_TYPES.BUSINESS_TRAVEL,
        EMISSION_TYPES.EMPLOYEE_COMMUTE,
        EMISSION_TYPES.CAPITAL_GOODS,
        EMISSION_TYPES.HOTEL_STAYS,
        EMISSION_TYPES.PURCHASED_SERVICES,
        EMISSION_TYPES.LEASED_ASSETS_UPSTREAM,
        EMISSION_TYPES.LEASED_ASSETS_DOWNSTREAM,
        EMISSION_TYPES.PROCESSING_OF_SOLD_PRODUCTS,
        EMISSION_TYPES.USE_OF_SOLD_PRODUCTS,
        EMISSION_TYPES.END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS,
        EMISSION_TYPES.FRANCHISES,
        EMISSION_TYPES.INVESTMENTS,
      ],
    },
    {
      value: EMISSIONS.CH4,
      title: 'CH4',
      emissionTypes: [
        EMISSION_TYPES.FUEL,
        EMISSION_TYPES.HEATING,
        EMISSION_TYPES.TRANSPORT,
      ],
    },
    {
      value: EMISSIONS.N2O,
      title: 'N2O',
      emissionTypes: [
        EMISSION_TYPES.FUEL,
        EMISSION_TYPES.HEATING,
        EMISSION_TYPES.TRANSPORT,
      ],
    },
    {
      value: EMISSIONS.CO2_BIOGENIC,
      title: 'CO2 Biogenic',
      emissionTypes: [
        EMISSION_TYPES.FUEL,
        EMISSION_TYPES.HEATING,
        EMISSION_TYPES.TRANSPORT,
        EMISSION_TYPES.USE_OF_SOLD_PRODUCTS,
        EMISSION_TYPES.END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS,
      ],
    },
    {
      value: EMISSIONS_ENERGY.LOCATION,
      title: 'CO2 (Location)',
      emissionTypes: [EMISSION_TYPES.ENERGY],
    },
    {
      value: EMISSIONS_ENERGY.MARKET,
      title: 'CO2 (Market)',
      emissionTypes: [EMISSION_TYPES.ENERGY],
    },
  ])

  // TODO: can be removed after sockets are added
  const isCreating = ref(false)
  // CRUD composed resources
  const createEmissionSource = async (
    emissionSource: IEmissionSourceWithFactors,
  ) => {
    const emissionFactors = emissionSource.emissionFactors || []
    const energyCoefficients = emissionSource.energyCoefficients || []

    try {
      isCreating.value = true
      // Create source
      const createdSource = await emissionSourceStore.create({
        ...emissionSource,
        id: undefined,
      })

      const promises: Promise<any>[] = [
        updateEmissionSourceFactors(
          emissionFactors.map((factor) => ({
            ...factor,
            id: undefined,
          })),
          (createdSource as WithId<IEmissionSource>).id,
        ),
        updateEmissionSourceEnergyCoefficients(
          energyCoefficients.map((energyCoefficient) => ({
            ...energyCoefficient,
            id: undefined,
          })),
          (createdSource as WithId<IEmissionSource>).id,
        ),
      ]

      if (emissionSource.transportTypeId) {
        promises.push(transportTypesEmissionSourcesStore.fetch())
      }

      await Promise.all(promises)

      return createdSource
    } finally {
      isCreating.value = false
    }
  }

  const deleteEmissionSource = async (
    emissionSource: Partial<IEmissionSource>,
  ) => {
    // Remove source
    await emissionSourceStore.remove(emissionSource)
  }

  const updateEmissionSourceFactors = async (
    emissionFactors: (IEmissionFactor | IEmissionFactorData)[],
    emissionSourceId: number,
  ) => bulkCrud(emissionFactorStore, emissionFactors, { emissionSourceId })

  const updateEmissionSourceEnergyCoefficients = async (
    energyCoefficients: Partial<IEnergyCoefficient>[],
    emissionSourceId: number,
  ) =>
    bulkCrud(energyCoefficientStore, energyCoefficients, {
      emissionSourceId,
    })

  const updateEmissionSource = async (
    emissionSource: WithId<IEmissionSourceWithFactors>,
  ) => {
    const emissionFactors = emissionSource.emissionFactors || []

    const energyCoefficients = emissionSource.energyCoefficients || []

    // Update source
    const [updatedEmissionSource] = await Promise.all([
      emissionSourceStore.patch(emissionSource),
      updateEmissionSourceFactors(emissionFactors, emissionSource.id),
      updateEmissionSourceEnergyCoefficients(
        energyCoefficients,
        emissionSource.id,
      ),
    ])

    return updatedEmissionSource
  }

  const isLoading = computed(
    () =>
      emissionCategoryStore.isLoading ||
      emissionFactorStore.isLoading ||
      energyCoefficientStore.isLoading ||
      emissionSourceStore.isLoading ||
      isCreating.value,
  )

  return {
    fetchAll,
    isLoading,
    emissionSourcesWithVersionedFactors,
    emissionSourcesWithPrefilledFactors,
    emissionCategories,
    emissionScopes,
    emissionsOptions,
    refetchEmissionSources,
    createEmissionSource,
    deleteEmissionSource,
    updateEmissionSource,
  }
})
